import React from 'react';
import './BeforeAfter.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Pagination, Navigation } from "swiper";
// images
import GoogleReview from '../../Images/google-review-enhance.jpg';
import User from '../../Images/user.jpg';
import Result1 from '../../Images/before-after-1.jpg';
import Result2 from '../../Images/before-after-2.jpg';
import Result3 from '../../Images/before-after-3.jpg';
import Facilities from '../Facilities/Facilities.jsx';
import Google from "../../Images/Google.png";
import { FaStar } from 'react-icons/fa';

import { MetaTags } from 'react-meta-tags';


const BeforeAfter = () => {
    return (
        <div>

            <MetaTags>
                <title>Best Hair Transplant Clinic in Bandra - Enhance Aesthetic Clinic</title>
                <meta title="Best Hair Transplant Clinic in Bandra - Enhance Aesthetic Clinic" />
                <meta name="description" content="Looking for the finest hair transplant clinic in Bandra? Our experienced surgeons provide natural hair restoration procedures. Book appointment now!" />
                <meta name="keywords" content="Best hair transplant in Bandra,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
                <link rel="canonical" href="https://enhanceaesthetic.in/hair-transplant-in-bandra" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Best Hair Transplant Clinic in Bandra - Enhance Aesthetic Clinic" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://enhanceaesthetic.in/hair-transplant-in-bandra" />
                <meta property="og:description" content="Looking for the finest hair transplant clinic in Bandra? Our experienced surgeons provide natural hair restoration procedures. Book appointment now!" />
                <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
            </MetaTags>

            {/* Before After Home */}
            <div className='beforeAfterHome'>
                <h1>Best Hair Transplant Clinic in Bandra</h1>
            </div>

            {/* Filter Gallery */}
            < Facilities />

            {/* testimonal */}
            <div className='testimonalSection'>
                <div className='testimonalHead'>
                    <h2>Our valuable Patient Reviews</h2>
                </div>
                <div className='TestimonalHead-Star-Con'>
                    <div className='Google-Test-Image-con'>
                        <div className='Google-Test-Image'>
                            <img src={Google} alt="Best hair transplant in Bandra" />
                        </div>
                    </div>
                    <h4>4.9</h4>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                </div>
                <div className='testimonalSwiper'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Autoplay, FreeMode, Pagination]}
                        className="mySwiper">
                        <SwiperSlide>
                            <div className='testimonalBox' id='style-1'>
                                <div className='testimonalTextBox' id='style-1'>
                                    <div className='testimonalStars'></div>
                                    <p>I met Dr. Ankur Saha for my Hair Loss treatment 7 months ago (Feb 2022). He completely transformed my look with his treatment. His team was extremely professional throughout the course of the treatment. They proactively follow up every month for regular checkups. The doctor himself is always available on WhatsApp/call to answer all your queries after the treatment. I am extremely satisfied and happy with my results and would highly recommend visiting him for all your hair loss related issues.</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt='Kevin Trivedi' />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Kevin Trivedi</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Dr Ankur is compassionate and honest. His experience and nuanced eye has helped me build my self confidence with his flawless work . He is well informed with the evolving world of skin and hair care . He is genuine and not driven by monetary gain like most other . Always an uplifting experience!</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Neetu Advani" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Neetu Advani</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Im from Spain did my prp there on the 5th October which was a holiday but due to my tight schedule Dr. Ankur Saha scheduled a slot for me on the day and came to work. The staff is very attentive and Dr. Ankus likes to explain all procedures calmly and ensure u feel comfortable. Will definitely do all my treatments there if possible. Highly recommend it</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Vinod B." />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Vinod B.</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Dr. Ankur Saha has an in-depth knowledge and provide personalized care. His team is extremely competent too. My overall experience surpassed my expectations.</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Yogesh Gaur" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Yogesh Gaur</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Dr ankur is a gem of a person he is someone whom i respect and appreciate for the good work he is doing  for everyone for him all his clients r same theres no differentiation  for high or low class   he adjusts with everything and for me too he is so understanding down to earth  positive and helping nature he just changed my life and my views towards life by changing my hair looks which was amazing after his treatment </p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Rajesh Kothari" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Rajesh Kothari</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>I would totally recommend Enhance hair and skin Aesthetic as the place is well equipped and Dr Ankur is excellent with his work and guidance.My experience with them had been great and i am very happy with the result 😊👍</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Navin Suvarna" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Navin Suvarna</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>I am writing to express my gratitude for the highly positive experience I had with Enhance Hair And Skin Aesthetic Clinic it has been 9 months from my FUE Hair Transplant procedure, Dr. Ankur Saha changed my life! I am a new person thanks to your care, concern and expertise! I am thoroughly impressed with my results. My hair looks perfect and I am truly grateful. Your knowledge and expertise will not go unrecognized; I will highly recommend anyone in need of this procedure. your staff went above and beyond my expectations. You truly educated me on the process and helped me feel at ease from day one. Thank you for a wonderful result. People at work are always commenting and giving me compliments on my hair and looks. I appreciate everything you have done for me. Thank you from the bottom of my heart!</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="PATRICK JEFFIN JOhnson" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>PATRICK JEFFIN JOhnson</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>This review is from my bottom of my heart ❤

                                        I am some one who tasted baldness in early 20s.So I used to think that I will do HT later, but I was scared of doing it.The reason being there are many inexperienced doctors who works only for the purpose of money.

                                        Destiny took me to DR Ankur saha at Enhance to put an end to my misery which haunted me for 10 years almost.
                                        I went to him  through a referral of my friend who had tremendous results.So I was not much worried about the result because I had already seen his work.

                                        So I went to do my HT on January 23 and the day I am writing this review is may 22 ie 4 months after Transplant(FUE).I can now proudly say that he has good artistic skills which is required for this profession I guess and great presence of mind and precision.I could only see divinity in his work which is rare these days.I believe he is destined to be a big name in this profession.</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="mahesh mukundan" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>mahesh mukundan</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>I’ve been visiting enhance since the last 2 month fir my Hair PRP and in just 2 months I’ve seen tremendous improvement in my hair quality and density.
                                        All thanks to Dr Ankur and Team at Enhance Aesthetic Clinic for all his help and support.
                                        Great work</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="FARAZ KHAN" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>FARAZ KHAN</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* video testimonals */}
            <div className='videoTestimonals'>
                <div className='videoTestimonalHead'>
                    <h2>Youtube Testimonial </h2>
                </div>
                <Swiper navigation={true}
                    slidesPerView={1}
                    spaceBetween={40}
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[FreeMode, Pagination, Navigation]}
                    className="mySwiper">
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/RMVSNTg4oG4?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8FSJgnVkDco?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kDtGX9y1sK8?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Du3dmaFih2M?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Qfo04BBlPlk?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/v8Ij5FcPIf4?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Ru_wXzO8nNg?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

        </div>
    )
}

export default BeforeAfter