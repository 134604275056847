import React from 'react';
import './About.css';
import CountUp from 'react-countup';
// images
import about from '../../Images/dr-ankur-award.jpeg';
import about2 from '../../Images/about.jpg';
import about3 from "../../Images/About Doctor.jpg"
import teamImg from '../../Images/team-image.jpg';
import DrReha from '../../Images/Dr-Reha_shetty.jpg';
import DrAnuragRathi from '../../Images/Dr. Anurag Rathi.jpeg';
import DrShilpa from '../../Images/DR-SHILPA-KHANAPURE.jpg';
import teamImg2 from "../../Images/enhance-clinic.jpeg";
import { MetaTags } from 'react-meta-tags';
// icons
import { FaHandHoldingMedical, FaMedal } from 'react-icons/fa';
import { IoMedkit } from 'react-icons/io5';
import { BiHappy } from 'react-icons/bi';

const About = () => {
    return (
        <div>

            <MetaTags>
                <title>Enhance Hair And Skin Clinic - Dr Ankur Saha</title>
                <meta title="Enhance Hair And Skin Clinic - Dr Ankur Saha" />
                <meta name="description" content="Enhance Hair and Skin Aesthetic Clinic, awarded with NATIONAL ICON AWARD for 'The Most Promising Aesthetic Clinic of the Year'" />
                <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
                <link rel="canonical" href="https://enhanceaesthetic.in/aboutus-enhance-hair-and-skin-clinic-in-bandra" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Enhance Hair And Skin Clinic - Dr Ankur Saha" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://enhanceaesthetic.in/aboutus-enhance-hair-and-skin-clinic-in-bandra" />
                <meta property="og:description" content="Enhance Hair and Skin Aesthetic Clinic, awarded with NATIONAL ICON AWARD for 'The Most Promising Aesthetic Clinic of the Year'" />
                <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
            </MetaTags>

            {/* banner */}
            <div className='aboutHome d-flex align-items-center'>
                <h1>Enhance Hair And Skin Clinic In Bandra</h1>
            </div>

            {/* about */}

            <div className='aboutUs'>
                <div className='container'>
                    <h2> About <span>Enhance Aesthetic Clinic</span></h2>
                    <div className='row'>
                        <div className='col-md-7 order-md-1 order-2 d-flex justify-content-center align-items-center'>
                            <div className='aboutUstext'>
                                <p>Enhance Hair and Skin Aesthetic Clinic, awarded with NATIONAL ICON AWARD for "The Most Promising Aesthetic Clinic of the Year" for hair care, skincare and hair transplant in 2022—one of the industry’s most esteemed award presented by Bollywood actor Amisha Patel, telecast in News24 Channel. The clinic located at the heart of the city Bandra in Mumbai, provides customised, FDA approved state-of-the-art aesthetic treatments using innovative international technologies from around the world combined with personal holistic approach to hair and skin care provided by a highly dedicated and skilled team of medical professionals who are extremely passionate towards providing the best care to the patients. The award winning clinic in Bandra is aesthetically designed which reflects the perfect harmony of beauty, elegance and advanced medicine to cater all the modern age aesthetic skin and hair concerns and is well equipped with latest advancements in field of medical aesthetics and hair restoration. </p>
                            </div>
                        </div>
                        <div className='col-md-5 order-md-2 order-1 d-flex justify-content-center align-items-center'>
                            <div className='aboutUsImg'>
                                <img src={teamImg} alt="Team Image" />
                            </div>
                        </div>
                        <div className='SecondAB'>
                            <div className='row'>
                                <div className='col-md-5 d-flex justify-content-center align-items-center' style={{ margin: "0px 0px 0px 0px" }}>
                                    <div className='aboutUsImg'>
                                        <img src={teamImg2} alt="Staff" />
                                    </div>
                                </div>
                                <div className='col-md-7 d-flex justify-content-center align-items-center'>
                                    <div className='aboutUstext'>
                                        <p>At Enhance Aesthetic Clinic, we feel everyone aspires to be beautiful! Our Mission is to provide unparalleled aesthetic results to all our patients keeping in mind the safety and efficacy of all our treatments. Enhance Aesthetic Clinic with its highly proficient team has developed very good repute in providing youthful and complete natural looking hairline and dense results with hair restoration with the latest FUE technique, strictly adhering to the ISHRS guidelines. The clinic has very good repute and expertise in facial injectables, Botox & Fillers, anti-ageing treatments, 7D HIFU, Q switched NdYAG laser, Diode Laser hair reduction and signature dermafacials.</p>
                                        <p>All clinical staff and doctors of Enhance Hair and Skin Aesthetic Clinic are also certified and trained in BLS, ACLS from American Heart Association (AHA) certified trainer. The clinic has well equipped state-of-the-art operation theatre with Boyle's machine, and modern lasers for hair reduction and other aesthetic treatments. </p>
                                        <p>
                                            In a short span, Enhance Aesthetic Clinic has built a loyal, notable client list that boasts Mumbai’s top celebrities including Bollywood and TV actors, cricketers, models, politicians, lawyers, entrepreneurs, professionals and thousands of happy glowing faces of people from all walks of life. While the patient list reads like the who's who of elite India, the proficient team at Enhance Aesthetic Clinic strongly believe that "EACH AND EVERY PATIENT IS A CELEBRITY" and are passionate towards providing every patient with highest quality care and best aesthetic results.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-7 order-md-2 order-1 d-flex justify-content-center align-items-center'>
                            <div className='aboutUstext'>
                                <p>With blessings of Internationally acclaimed Hair Restoration Surgeon and our mentor Dr. Manoj Khanna</p>
                            </div>
                        </div>
                        <div className='col-md-5 order-md-1 order-2 d-flex justify-content-center align-items-center'>
                            <div className='aboutUsImg'>
                                <img src={about2} alt="best skin doctor" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* conunter */}
            <div className='counterSection'>
                <div className='counterHead'>
                    {/* <h2>Heading</h2> */}
                    <p>Fall in love with your hair and skin again and again.Have your pure aesthetics here.</p>
                </div>
                <div className='counterBoxes'>
                    <div className='row'>
                        {/* <div className='col-md-3 p-lg-4  d-flex justify-content-center align-items-center'>
                            <div className='counterBox'>
                                <div className='counterBoxIcon'>
                                    <FaMedal />
                                </div>
                                <div className='counterBoxText'>
                                    <h4>+10</h4>
                                    <h6>Years of experience</h6>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-md-4 p-lg-4  d-flex justify-content-center align-items-center'>
                            <div className='counterBox'>
                                <div className='counterBoxIcon'>
                                    <IoMedkit />
                                </div>
                                <div className='counterBoxText'>
                                    <h4>
                                        <CountUp end={1000} duration={10} />
                                    </h4>
                                    <h6>Succesful Treatments</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 p-lg-4  d-flex justify-content-center align-items-center'>
                            <div className='counterBox'>
                                <div className='counterBoxIcon'>
                                    <FaHandHoldingMedical />
                                </div>
                                <div className='counterBoxText'>
                                    <h4>
                                        <CountUp end={100} duration={10} />
                                    </h4>
                                    <h6>Services</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 p-lg-4  d-flex justify-content-center align-items-center'>
                            <div className='counterBox'>
                                <div className='counterBoxIcon'>
                                    <BiHappy />
                                </div>
                                <div className='counterBoxText'>
                                    <h4>
                                        <CountUp end={+5472} duration={10} />
                                    </h4>
                                    <h6>Happy Patients</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* our doctors */}
            <div className='doctorsSection'>
                <div className='container'>
                    <div className='doctorsHead'>
                        <h2>Meet Our Doctors</h2>
                    </div>
                    <div className='row m-2'>
                        {/* dr Ankur */}
                        <div className='col-md-7 order-2 order-md-1'>
                            <div className='doctorDetail' >
                                <h4>Dr. Ankur Saha</h4>
                                <p>“Medical Aesthetics is a beautiful amalgamation of modern medicine and Art. Great aesthetic results always need a perfect combination of medical expertise and good aesthetic sense” --- Dr. Ankur Saha, medical head at Enhance Hair and Skin Aesthetic Clinic, Mumbai.</p>
                                <p>Known for his good aesthetic sense and creative skills, Dr. Ankur is passionate about enhancing the way people look making them youthful and rejuvenated. His professional span has been marked with passionate learning, diligent training and in-depth guidance by some of the leading experts in the field of Hair Restoration and Medical aesthetics.</p>
                                <p>After completing MBBS from Maharashtra University of Health Sciences from Mumbai, his keen interest and passion in the dynamic subject of medical aesthetics and hair restoration motivated him to pursue advanced training in the subject. He has done postgraduate Diploma in Dermatology (UK), Fellowship in Aesthetic Medicine (Germany), MD AM.To further upgrade his hands on and clinical skills he has also done several other courses including Dip. Clinical Trichology, Dip. Medical Cosmetology, and is Certified in Lasers & Advanced Skin Esthetics. He is formally trained in anti-ageing treatments including Botox, Fillers, Vampire facelift, threadlift, microneedling, platelet rich plasma, modern lasers.</p>
                            </div>
                        </div>
                        <div className='col-md-5 order-1 order-md-2 d-flex justify-content-center'>
                            <div className='doctorImg drAnkurImg'>
                                <img src={about} alt="Dr. Ankur Saha" />
                            </div>
                        </div>
                    </div>
                    <div className='AnkurSaha2'>
                        <div className='row m-2'>
                            {/* dr Ankur 2*/}
                            <div className='col-md-5 order-1 order-md-1 d-flex justify-content-center'>
                                <div className='doctorImg drAnkurImg'>
                                    <img src={about3} alt="best hair doctor" />
                                </div>
                            </div>
                            <div className='col-md-7 order-2 order-md-2'>
                                <div className='doctorDetail'>
                                    <p>Motivated to learn from the best, he further received extensive training in hair restoration surgery from internationally acclaimed hair restoration and plastic surgeon, Dr. Manoj Khanna (Past President of Association of Hair Restoration Surgeons, India) and has worked with him for several years, advancing his skills in the subject. He has attended several international worshops and medical conferences including symposia on Botox advanced indications and AMI Workshop, Singapore for advanced training in facial injectables with Cadaver dissection, under world renowned plastic surgeon Dr. Woffles Wu.</p>
                                    <p>He is Life Member of Association of Facial Aestheticians and Aesthetic Surgeons of India (AFAASI), Member of International Society of Hair Restoration Surgery (ISHRS), Member of International Society of Dermatology (ISD) and International Academy of Cosmetic Dermatology (IACD).</p>
                                    <p>With his proficient team he received National Icon Award for Enhance Hair and Skin Aesthetic Clinic for the title 'Most promising Aesthetic Clinic of the year" in 2022. He actively participates in health awareness camps and workshops held at various corporate offices and other institutions and is invited as speaker by such institutions. His articles have been featured in reputed magazines like Femina and his health talks have aired on several occasions in Radio City 91.1 FM. Dr. Ankur is known for his integrity, ethics, compassion towards his patients and his passionate to provide every patient with best aesthetic results and utmost care.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Dr Reha Shety */}
                    <div className='row m-2'>
                        <div className='col-md-7 order-md-1 order-2'>
                            <div className='doctorDetail'>
                                <h4>Dr. Rhea Shetty </h4>
                                <p>Dr. Rhea is a consultant dermatologist at Enhance Hair and Skin Aesthetic Clinic, having special interest in clinical dermatology and aesthetics, and is highly dedicated and focused on maximising patient care with diagnosis and management of variety of skin conditions. After completing her MBBS from MGM Medical College, Navi Mumbai, she completed her Postgraduate Diploma in Dermatology And Venereology DDV, Fellowship in Infectious Disease and Certificate course in Diabetes Management. Along with the skilled team at Enhance Aesthetic Clinic, Dr. Rhea is proficient in handling various day care dermatology procedures, modern lasers in cosmetic dermatology and hair restoration surgery. She has participated and organised various health and leprosy camps and has presented posters on current topics in dermatology and is passionate to provide best treatment plan and care to the patients.</p>
                            </div>
                        </div>
                        <div className='col-md-5 order-md-2 order-1 d-flex justify-content-center'>
                            <div className='doctorImg drRehanImg'>
                                <img src={DrReha} alt="Dr. Rhea Shetty" />
                            </div>
                        </div>
                    </div>

                    {/* Dr Prashant */}
                    <div className='row m-2'>
                        <div className='col-md-5 order-1 order-md-2 d-flex justify-content-center'>
                            <div className='doctorImg drPrashanthImg' >
                                <img src={DrAnuragRathi} alt="Dr. Prashanth HK" />
                            </div>
                        </div>
                        <div className='col-md-7 order-2 order-md-2'>
                            <div className='doctorDetail'>
                                <h4>Dr. Anurag Rathi</h4>
                                <p>Dr. Anurag Rathi is Consultant Anesthesiologist at Enhance Hair and Skin Aesthetic Clinic. He has completed MBBS, MD Anesthesiology and is highly passionate and skilled in providing highest quality care to the patients and is proficient in pre anesthesia check up and fitness for surgery, evaluation of medical reports, management of critical care and anesthesia for hair restoration and other procedures.</p>
                            </div>
                        </div>
                    </div>

                    {/* Dr Shilpa */}
                    <div className='row m-2'>
                        <div className='col-md-7 roder-md-1 order-2'>
                            <div className='doctorDetail'>
                                <h4>Dr Shilpa Khanapure</h4>
                                <p>Dr. Shilpa is Consultant Anesthesiologist at Enhance Hair and Skin Aesthetic Clinic. She has completed MBBS, MD Anesthesiology and is highly dedicated, efficient and compassionate towards the patients. She is responsible for pre anesthesia checkup and fitness, evaluation of patient medical history and clinical reports, critical care and anesthesia for hair restoration surgery.</p>
                            </div>
                        </div>
                        <div className='col-md-5 order-md-2 order-1 d-flex justify-content-center'>
                            <div className='doctorImg drShilpaImg ' >
                                <img src={DrShilpa} alt="Dr Shilpa Khanapure" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About