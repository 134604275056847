export const menuItems = [
    {
        path: "/",
        title: "Home",
    },
    {
        path: "/aboutus-enhance-hair-and-skin-clinic-in-bandra",
        title: "About",
    },
    {
        title: "Services",
        submenu: [{
            title: "Hair Treatment",
            submenu: [{
                id:101,
                path: "/hair-loss-treatment-in-bandra",
                title: "Hair Loss",
            },
            {
                id:102,
                path: "/fue-hair-transplant-clinic-in-bandra",
                title: "FUE Hair Transplant",
            },
            {
                id:103,
                path: "/moustache-beard-transplantation-in-bandra",
                title: "Beard and Moustache Hair Transplant",
            },
            {
                id:104,
                path: "/eyebrow-hair-transplant-in-bandra",
                title: "Eyebrow Hair Transplant",
            },
            {
                id:105,
                path: "/prp-treatment-in-bandra",
                title: "Platelet Rich Plasma",
            },
            {
                id:106,
                path: "/growth-factor-concentration-in-bandra",
                title: "Growth Factor Concentrate",
            },
            {
                id:107,
                path: "/mesotherapy-for-hair-loss-in-bandra",
                title: "Mesotherapy",
            },
            {
                id:108,
                path: "/alopecia-areata-treatment-in-bandra",
                title: "Alopecia Treatment",
            },
            {
                id:109,
                path: "/scalp-micropigmentation-in-bandra",
                title: "Scalp Micropigmentation",
            },
            {
                id:110,
                path: "/dandruff-treatment-in-bandra",
                title: "Dandruff Treatment",
            },
            ],
        },
        {
            title: "Youth Preserve Antiageing Treatment",
            submenu: [{
                path: "/botox-wrinkle-treatment-in-bandra",
                title: "Botox® Wrinkle Treatment",
            },
            {
                path: "/hyperhidrosis-treatment-in-bandra",
                title: " Hyperhidrosis ",
            },
            {
                path: "/jawline-slimming-treatment-in-bandra",
                title: "Jawline Slimming",
            },
            {
                path: "/dermal-fillers-treatment-in-bandra",
                title: " Juvederm® | Restlane® Dermal Fillers",
            },
            {
                path: "/7d-hifu-treatment-clinic-in-bandra",
                title: " 7D HIFU",
            },
            {
                path: "/thread-lifts-treatment-clinic-in-bandra",
                title: " Threadlift",
            },
            {
                path: "/juvederm-volite-treatment-in-bandra",
                title: " Volite Skin Quality Injectable ",
            },
            ],
        },
        {
            title: "Permanent Laser Hair Reduction",
            submenu: [{
                path: "/permanent-laser-hair-reduction-in-bandra",
                title: " Triple Wavelenght Diode Laser Hair Reduction ",
            },
            ],
        },
        {
            title: "Pigmentation Treatment",
            submenu: [{
                path: "/chemical-peel-treatment-in-bandra",
                title: "Enhance Chemical Peels",
            },
            {
                path: "/q-switch-nd-yag-laser-in-bandra",
                title: " Q Switch ND Yag Laser ",
            },
            {
                path: "/glutaglow-iv-drip-enhance-aesthetic-in-bandra",
                title: " Glutaglow IV Drip ",
            },
            ],
        },
        {
            title: "Enhance Medi Facials",
            submenu: [{
                path: "/celebrity-vampire-facial-enhance-aesthetic-in-bandra",
                title: "Celebrity Vampire Facial ",
            },
            {
                path: "/carbon-laser-facial-in-bandra",
                title: " Carbon Laser Facial ",
            },
            {
                path: "/7d-hifu-facial-treatment-in-bandra",
                title: "7D HIFU Facial",
            },

            {
                path: "/acne-facial-peel-treatment-in-bandra",
                title: "Acne Facial/ Peel ",
            },
            {
                path: "/microdermabrasion-skin-polishing-treatment-in-bandra",
                title: " Microdermabrasion / Skin Polishing ",
            },
            {
                path: "/superjet-infuse-facial-in-bandra",
                title: " Superjet and Infuse Facial",
            },
            {
                path: "/pumpkin-facial-treatment-in-bandra",
                title: "Pumpkin Facial",
            },
            // {
            //     path: "/servcieDetail?id=509",
            //     title: "Enhance Brightning Facial",
            // },
            {
                path: "/best-photofacial-treatment-in-bandra",
                title: "Enhance Photofacial",
            },
            {
                path: "/bridal-groom-care-in-bandra",
                title: "Enhance Bridal Care and Groom Care",
            },
            {
                path: "/collagen-induction-therapy-in-bandra",
                title: "Collagen Induction Facial",
            },
            {
                path: "/zo-red-carpet-facial-peel-in-bandra",
                title: "Zo® obagi Red Carpet Facial",
            },
            ],
        },
        {
            title: "Acne and Scar Management",
            submenu: [{
                path: "/best-acne-peel-treatment-in-bandra",
                title: "Acne Peel ",
            },
            {
                path: "/microneedling-treatment-in-bandra",
                title: " Microneedling",
            },
            {
                path: "/microneedling-radiofrequency-in-bandra",
                title: "MNRF",
            },
            {
                path: "/fractional-co2-laser-skin-treatment-in-bandra",
                title: "Fractional CO2 Laser",
            },
            {
                path: "/best-rf-cautery-treatment-in-bandra",
                title: "RF Cautery / Skin Tag, Wart Removal",
            },
            ],
        },
        ],
    },
    {
        path: "/hair-transplant-in-bandra",
        title: "Before After",
    },
    {
        path: "/clinic-gallery",
        title: "Gallery ",
    },

    {
        path: "/contact-us",
        title: "Contact Us",
    },
];