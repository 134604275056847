import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <div className='footerSection'>
            <h6>Copyright © Enhance Hair and skin aesthetic clinic</h6>
            <h5>Designed by <strong><a style={{ color: "#c7514e" }} href='https://skdm.in/' target='blank'>Shree Krishna Digital Marketing</a></strong></h5>
        </div>
    )
}

export default Footer;