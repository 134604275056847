import React from "react";
import "./ServiceDetail/ServiceDetail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import { MetaTags } from 'react-meta-tags';
// images
import Result1 from "../Images/before-after-1.jpg";
import Result2 from "../Images/before-after-2.jpg";
import Result3 from "../Images/before-after-3.jpg";
import serviceImg from "../Final GIF/LASER HAIR REDUCTION.gif";
import serviceImg2 from "../Images/serviceDetail-2.jpg";

const LazerHairReduction = () => {
  return (
    <div>

      <MetaTags>
        <title>Laser Hair Reduction Treatment | Enhance Aesthetic Clinic</title>
        <meta title="Laser Hair Reduction Treatment | Enhance Aesthetic Clinic" />
        <meta name="description" content="Get rid of undesirable hair with our Laser Hair Reduction treatment. Experience a everlasting solution for smooth and hair-loose skin." />
        <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
        <link rel="canonical" href="https://enhanceaesthetic.in/laser-hair-reduction-treatment-in-bandra" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Laser Hair Reduction Treatment | Enhance Aesthetic Clinic" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enhanceaesthetic.in/laser-hair-reduction-treatment-in-bandra" />
        <meta property="og:description" content="Get rid of undesirable hair with our Laser Hair Reduction treatment. Experience a everlasting solution for smooth and hair-loose skin." />
        <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
      </MetaTags>


      {/* home */}
      <div className="serviceDetailHome">
        <h1>Triple Wavelenght Diode Laser Hair Reduction</h1>
      </div>

      {/* service details */}
      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Triple Wavelenght Diode Laser Hair Reduction</h2>
                <p>
                  Safe, Comfortable and USFDA approved Gold Standard Diode Technology operated by qualified doctors using protocols developed for Indian skin by Enhance Hair & Skin Aesthetic Clinic. Many of us are very uncomfortable with our unwanted hair, it looks unattractive and makes it unhygienic.
                </p>
                <p>
                  If you want to get rid of shaving, tweezing, or waxing to remove unwanted hairs then this treatment is for you.                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={serviceImg} alt="service Images" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default LazerHairReduction;
