import {
    useState,
    useEffect,
    useRef
} from "react";
import { Link } from "react-router-dom";
import { menuItems } from "../menuItems";
import $ from "jquery";

import Dropdown from "./Dropdown";

const MenuItems = ({
    items,
    depthLevel
}) => {
    const [dropdown, setDropdown] = useState(false);

    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        document.addEventListener("click", handler);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("click", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };
    const onMouseClick = () => { 
        const tog = document.getElementById("navbarToggler");
        if (
        window.innerWidth < 768 &&
        tog.classList.contains("show")
        ) {
        tog.collapse({ toggle: false }).collapse("hide");
        } else {
        console.log("no");
        }
    };

    



    const demo = (path) => {
        console.log(path)

    }


    $('.demo').on('click', function(){
        // $('.navbar-collapse').collapse('hide');
        $(".navbar-collapse").removeClass("show");
    });
    return (<li className="menu-items"
        ref={
            ref
        }
        onMouseEnter={
            onMouseEnter
        }
        onMouseLeave={
            onMouseLeave
        }
    >
        {
            items.submenu ? (
                <>
                    <button type="button" aria-haspopup="menu"
                        aria-expanded={
                            dropdown ? "true" : "false"
                        }
                        onClick={() => setDropdown((prev) => !prev)}>
                        {
                            items.title
                        } {
                            " "
                        } {depthLevel > 0 ? <span> &raquo; </span> : <span className="arrow" />
                        }
                    </button>
                    <Dropdown depthLevel={depthLevel}
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                <>
                    <Link className="demo" to={items.path}
                        onClick={() => {
                            demo(items.path);
                            onMouseClick();
                        }
                        }

                    >
                        {
                            items.title
                        }
                    </Link>
                </>
            )
        } </li>
    );
};

export default MenuItems;
