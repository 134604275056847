import React from 'react';
import "../WhatApp/ComBtn.css";
import { BsWhatsapp } from 'react-icons/bs';

const ComBtn = () => {
    return (
        <>
            <section className='WhtasCom-Btn'>
            <a href='https://wa.me/+918575099099' target='_blank'>
                    <div className='Com-btn-con'>
                        <BsWhatsapp />
                    </div>
                </a>
            </section>
        </>
    )
}

export default ComBtn