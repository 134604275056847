import React, { useState } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { MetaTags } from 'react-meta-tags';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FreeMode, Autoplay, Pagination, Navigation } from "swiper";
// Images
import AboutImg1 from '../../Images/dr-ankur-award.jpeg';
import AboutImg2 from '../../Images/enhance-clinic.jpeg';
import HairTreatment from '../../Images/hair-treatment.jpg';
import AntiAgeingTreatment from '../../Images/Anti-Ageing-treatment.jpg';
import LazerHairTreatment from '../../Images/laser-hair-removal.jpg';
import PigmentationTreatment from '../../Images/pigmentation-Treatment.jpg';
import EnhanceMediFacials from '../../Images/enhance-medi-facial.jpg';
import AcneAndScareManagement from '../../Images/acne-scar-treatment.jpg';
import User from '../../Images/user.jpg';
import GoogleReview from '../../Images/google-review-enhance.jpg';
import MaleHairTreatmet from '../../Images/male-hair-treatment.jpg'
import MaleHairLazer from '../../Images/male-hair-lazer-treatment.jpg';
// icons
import { GrCertificate } from 'react-icons/gr';
import { BsPlayFill } from 'react-icons/bs';
import { MdFaceRetouchingNatural } from 'react-icons/md';
import { FaStar } from 'react-icons/fa';

import Result1 from '../../Images/before-after-1.jpg';
import Result2 from '../../Images/before-after-2.jpg';
import Result3 from '../../Images/before-after-3.jpg';
import teamImg2 from "../../Images/enhance-clinic.jpeg";


/////// Services //////////

import HairLoss01 from "../../Images/B & A Images/Hair Loss B&A 03.jpeg";
import HairLoss02 from "../../Images/B & A Images/Hair Loss B&A 02.jpeg";

import YouthPreserve01 from "../../Images/B & A Images/Botox Wrinkle Treatment B&A 01.jpeg";
import YouthPreserve02 from "../../Images/B & A Images/Eyebrow Hair Transplant B & A 02.jpeg";

import LaserHairRemoval01 from "../../Images/Service Main Image/Triple Wavelenght Diode Laser Hair Reduction.jpg";
import LaserHairRemoval02 from "../../Images/Service Main Image/body hair removal.png";

import PigmentationTreatment01 from "../../Images/B & A Images/PIGMENTATION BEFORE AFTER.jpeg";
import PigmentationTreatment02 from "../../Images/Service Main Image/pigmentation.jpg";

import EnhanceMediFacials01 from "../../Images/Service Main Image/EnhanceMediFacial 2.jpg";
import EnhanceMediFacials02 from "../../Images/Service Main Image/Acne Facial Peel.jpg";

import AcneandScaremanagement01 from "../../Images/Service Main Image/Zo obagi Red Carpet Facial.jpg";
import AcneandScaremanagement02 from "../../Images/Service Main Image/Acne and Scare management.jpg";
import HomeTestimonial01 from "../../Images/B & A Images/Hair Loss B&A 01.jpeg";
import HomeTestimonial02 from "../../Images/B & A Images/Hair Loss B&A 02.jpeg";
import HomeTestimonial03 from "../../Images/B & A Images/Hair Loss B&A 03.jpeg";
import HomeTestimonial04 from "../../Images/B & A Images/Hair Loss B&A 04.jpeg";
import HomeTestimonial05 from "../../Images/B & A Images/Lip B&A 02.jpeg";
import HomeTestimonial06 from "../../Images/B & A Images/Hair Loss B&A 05.jpeg";
import HomeTestimonial07 from "../../Images/B & A Images/BOTOX FILLER BEFORE AFTER 01.jpeg";
import HomeTestimonial08 from "../../Images/B & A Images/Beard and Moustache Hair Transplant B&A 01.jpeg";
import HomeTestimonial09 from "../../Images/B & A Images/Eyebrow Hair Transplant B & A 02.jpeg";
import HomeTestimonial10 from "../../Images/B & A Images/Hair Loss B&A 09.jpeg";
import HomeTestimonial11 from "../../Images/B & A Images/Botox Wrinkle Treatment B&A 01.jpeg";
import HomeTestimonial12 from "../../Images/B & A Images/Botox Wrinkle Treatment B&A 02.jpeg";
import HomeTestimonial13 from "../../Images/B & A Images/Botox Wrinkle Treatment B&A 04.jpeg";
import HomeTestimonial14 from "../../Images/B & A Images/Lip & MIdface Facial B & A 01.jpeg";
import HomeTestimonial15 from "../../Images/B & A Images/Lip B&A 01.jpeg";
import HomeTestimonial16 from "../../Images/B & A Images/PIGMENTATION BEFORE AFTER.jpeg";
import HomeTestimonial17 from "../../Images/B & A Images/Skin-Pigmentation.jpeg";

import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';

import Google from "../../Images/Google.png";


const Home = () => {

    var divisor = document.getElementById("divisor"),
        handle = document.getElementById("handle"),
        slider = document.getElementById("slider");

    function moveDivisor() {
        handle.style.left = slider.value + "%";
        divisor.style.width = slider.value + "%";
    }

    window.onload = function () {
        moveDivisor();
    };
    return (
        <div>


            <MetaTags>
                <title>Best Hair and Skin Clinic In Mumbai - Enhance Aesthetic Clinic</title>
                <meta title="Best Hair and Skin Clinic In Mumbai - Enhance Aesthetic Clinic" />
                <meta name="description" content="Enhance Aesthetic Clinic is the best hair and skin clinic in Mumbai. Get expert care for your hair and skin at our clinic. Book your appointment now!" />
                <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
                <link rel="canonical" href="https://enhanceaesthetic.in/" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Best Hair and Skin Clinic In Mumbai - Enhance Aesthetic Clinic" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://enhanceaesthetic.in/" />
                <meta property="og:description" content="Enhance Aesthetic Clinic is the best hair and skin clinic in Mumbai. Get expert care for your hair and skin at our clinic. Book your appointment now!" />
                <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
            </MetaTags>


            {/* Banner */}

            <section className="homeSection">
                <div className='conatiner'>
                    <div id="home">
                        <Swiper slidesPerView={1}
                            spaceBetween={30}
                            freeMode={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper">

                            <SwiperSlide>
                                <div className='HomeBanner' id="firstSlide">
                                    <div className="homeContent">
                                        <Zoom cascade>
                                            <h1 className='d-none'>Best Hair and Skin Clinic In Mumbai</h1>
                                            <h2>ENHANCE</h2>
                                            <h6>HAIR  AND  SKIN</h6>
                                            <h4>AESTHETIC CLINIC</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='HomeBanner' id="secondSlide">
                                    <div className="homeContent">
                                        <Zoom cascade>
                                            <h2>ENHANCE</h2>
                                            <h6>HAIR  AND  SKIN</h6>
                                            <h4>AESTHETIC CLINIC</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='HomeBanner' id="thirdSlide">
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='HomeBanner' id="forthSlide">
                                    <div className="homeContent">
                                        <Zoom cascade>
                                            <h2>ENHANCE</h2>
                                            <h6>HAIR  AND  SKIN</h6>
                                            <h4>AESTHETIC CLINIC</h4>
                                        </Zoom>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </section>


            {/* About */}
            <div className='aboutSection'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='aboutText'>
                            <Slide left>
                                <h2>Enhance <br /> Aesthetic Clinic</h2>
                            </Slide>
                            <p>
                                <Zoom>
                                    Enhance Hair and Skin Aesthetic Clinic, awarded with NATIONAL ICON AWARD for "The Most Promising Aesthetic Clinic of the Year" for hair care, skincare and hair transplant in 2022—one of the industry’s most esteemed award presented by Bollywood actor Amisha Patel, telecast in News24 Channel. The clinic located at the heart of the city Bandra in Mumbai, provides customised, FDA approved state-of-the-art aesthetic treatments using innovative international technologies from around the world combined with personal holistic approach to hair and skin care provided by a highly dedicated and skilled team of medical professionals who are extremely passionate towards providing the best care to the patients. The award winning clinic in Bandra is aesthetically designed which reflects the perfect harmony of beauty, elegance and advanced medicine to cater all the modern age aesthetic skin and hair concerns and is well equipped with latest advancements in field of medical aesthetics and hair restoration.
                                </Zoom>
                            </p>
                            <button>
                                <Zoom left cascade>
                                    <Link to="/aboutus-enhance-hair-and-skin-clinic-in-bandra">
                                        Know more
                                    </Link>
                                </Zoom>
                            </button>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='aboutImagesMain'>
                            <div className='aboutMainImage1'>
                                <div className='row'>
                                    <div className='col-4 d-flex justify-content-end align-items-end'>
                                        <div className='experienceBox'>
                                            <div className='experienceIcon'>
                                                <MdFaceRetouchingNatural />
                                            </div>
                                            <h4>
                                                <CountUp end={5472} duration={10} />
                                            </h4>
                                            <p>Happy patients</p>
                                        </div>
                                    </div>
                                    <div className='col-8'>
                                        <div className='aboutImg1'>
                                            <img src={teamImg2} alt='Enhance Aesthetic team' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='aboutMainImage2'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='aboutImg2'>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/fIoYVbmaliM?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* /////////////////////////////// Section 5 /////////////////////////////// */}

            <section className='section-5'>
                <div className='beforeAfterImages'>
                    <div className='beforeAfterGallery'>
                        <div className='videoTestimonals'>
                            <div className='videoTestimonalHead'>
                                <Zoom cascade>
                                    <h2>Results after our Treatment</h2>
                                </Zoom>
                            </div>
                            <Swiper navigation={true}
                                slidesPerView={1}
                                spaceBetween={30}
                                freeMode={true}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 40,
                                    },
                                }}
                                modules={[Autoplay, FreeMode, Pagination, Navigation]}
                                className="mySwiper">
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial01} alt="Top hair clinic in mumbai" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial02} alt="How to get thick hair" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial03} alt="Glowing skin" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial04} alt="Beard hair transplant" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial09} alt="Dandruff treatment" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial08} alt="Meso therapy" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial11} alt="GFC treatment" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial13} alt="Dermal fillers" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial15} alt="Hifu skin tightening" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial16} alt="Skin brightening" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial05} alt="Anti wrinkle treatment" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial06} alt="Anti ageing treatment" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial07} alt="Lip Filler" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial10} alt="Best aesthetic clinic" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='youtubeFrame befafbox d-flex justify-content-center'>
                                        <img src={HomeTestimonial12} alt="Skin brightening treatment" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            {/* video testimonals */}
            <div className='videoTestimonals'>
                <div className='videoTestimonalHead'>
                    <h2>Youtube Testimonial </h2>
                </div>
                <Swiper navigation={true}
                    slidesPerView={1}
                    spaceBetween={40}
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Autoplay, FreeMode, Pagination, Navigation]}
                    className="mySwiper">
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/RMVSNTg4oG4?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8FSJgnVkDco?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Qfo04BBlPlk?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/v8Ij5FcPIf4?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kDtGX9y1sK8?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Du3dmaFih2M?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Ru_wXzO8nNg?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/q23C--92UTo?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/fIoYVbmaliM?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/a-CgDfE7ltw?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='youtubeFrame d-flex justify-content-center'>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/dcRrswu5xvk?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                    </SwiperSlide>
                </Swiper>
            </div>


            {/* testimonal */}
            <div className='testimonalSection'>
                <div className='testimonalHead'>
                    <h2>Our valuable Patient Reviews</h2>
                </div>
                <div className='TestimonalHead-Star-Con'>
                    <div className='Google-Test-Image-con'>
                        <div className='Google-Test-Image'>
                            <img src={Google} alt="Enhance Clinic in bandra" />
                        </div>
                    </div>
                    <h4>4.9</h4>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                    <span>
                        <FaStar />
                    </span>
                </div>
                <div className='testimonalSwiper'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Autoplay, FreeMode, Pagination]}
                        className="mySwiper">
                        <SwiperSlide>
                            <div className='testimonalBox' id='style-1'>
                                <div className='testimonalTextBox' id='style-1'>
                                    <div className='testimonalStars'></div>
                                    <p>I met Dr. Ankur Saha for my Hair Loss treatment 7 months ago (Feb 2022). He completely transformed my look with his treatment. His team was extremely professional throughout the course of the treatment. They proactively follow up every month for regular checkups. The doctor himself is always available on WhatsApp/call to answer all your queries after the treatment. I am extremely satisfied and happy with my results and would highly recommend visiting him for all your hair loss related issues.</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Kevin Trivedi" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Kevin Trivedi</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Dr Ankur is compassionate and honest. His experience and nuanced eye has helped me build my self confidence with his flawless work . He is well informed with the evolving world of skin and hair care . He is genuine and not driven by monetary gain like most other . Always an uplifting experience!</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Neetu Advani" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Neetu Advani</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Im from Spain did my prp there on the 5th October which was a holiday but due to my tight schedule Dr. Ankur Saha scheduled a slot for me on the day and came to work. The staff is very attentive and Dr. Ankus likes to explain all procedures calmly and ensure u feel comfortable. Will definitely do all my treatments there if possible. Highly recommend it</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Vinod B." />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Vinod B.</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Dr. Ankur Saha has an in-depth knowledge and provide personalized care. His team is extremely competent too. My overall experience surpassed my expectations.</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Vinod B." />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Yogesh Gaur</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>Dr ankur is a gem of a person he is someone whom i respect and appreciate for the good work he is doing  for everyone for him all his clients r same theres no differentiation  for high or low class   he adjusts with everything and for me too he is so understanding down to earth  positive and helping nature he just changed my life and my views towards life by changing my hair looks which was amazing after his treatment </p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Rajesh Kothari" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Rajesh Kothari</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>I would totally recommend Enhance hair and skin Aesthetic as the place is well equipped and Dr Ankur is excellent with his work and guidance.My experience with them had been great and i am very happy with the result 😊👍</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="Navin Suvarna" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>Navin Suvarna</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>I am writing to express my gratitude for the highly positive experience I had with Enhance Hair And Skin Aesthetic Clinic it has been 9 months from my FUE Hair Transplant procedure, Dr. Ankur Saha changed my life! I am a new person thanks to your care, concern and expertise! I am thoroughly impressed with my results. My hair looks perfect and I am truly grateful. Your knowledge and expertise will not go unrecognized; I will highly recommend anyone in need of this procedure. your staff went above and beyond my expectations. You truly educated me on the process and helped me feel at ease from day one. Thank you for a wonderful result. People at work are always commenting and giving me compliments on my hair and looks. I appreciate everything you have done for me. Thank you from the bottom of my heart!</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="PATRICK JEFFIN JOhnson" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>PATRICK JEFFIN JOhnson</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>This review is from my bottom of my heart ❤

                                        I am some one who tasted baldness in early 20s.So I used to think that I will do HT later, but I was scared of doing it.The reason being there are many inexperienced doctors who works only for the purpose of money.

                                        Destiny took me to DR Ankur saha at Enhance to put an end to my misery which haunted me for 10 years almost.
                                        I went to him  through a referral of my friend who had tremendous results.So I was not much worried about the result because I had already seen his work.

                                        So I went to do my HT on January 23 and the day I am writing this review is may 22 ie 4 months after Transplant(FUE).I can now proudly say that he has good artistic skills which is required for this profession I guess and great presence of mind and precision.I could only see divinity in his work which is rare these days.I believe he is destined to be a big name in this profession.</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="mahesh mukundan" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>mahesh mukundan</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='testimonalBox'>
                                <div className='testimonalTextBox'>
                                    <div className='testimonalStars'></div>
                                    <p>I’ve been visiting enhance since the last 2 month fir my Hair PRP and in just 2 months I’ve seen tremendous improvement in my hair quality and density.
                                        All thanks to Dr Ankur and Team at Enhance Aesthetic Clinic for all his help and support.
                                        Great work</p>
                                </div>
                                <div className='testimonalImgBox'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='testimonalCardImg'>
                                                <img src={User} alt="FARAZ KHAN" />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <p>FARAZ KHAN</p>
                                            <div className='Revies-Star-Con'>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                                <span>
                                                    <FaStar />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* services */}
            <div className='services'>
                <div className='container'>
                    <div className='servicesHead'>
                        <Zoom cascade>
                            <h2>Popular aesthetic treatments</h2>
                        </Zoom>
                    </div>
                    <div className='servicesInner'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='serviceCard'>
                                    <div className='serviceCardImg'>
                                        <Swiper slidesPerView={1}
                                            spaceBetween={30}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, FreeMode, Pagination]}
                                            className="mySwiper">

                                            <SwiperSlide><img src={MaleHairTreatmet} alt='Male Hair Treatmet' /></SwiperSlide>
                                            <SwiperSlide><img src={HairLoss01} alt='Male Hair Treatmet service' /></SwiperSlide>
                                            <SwiperSlide><img src={HairLoss02} alt='best Male Hair Treatmet' /></SwiperSlide>
                                        </Swiper>

                                    </div>
                                    <div className='serviceCardTitle'>
                                        <Zoom cascade>
                                            <h4>Hair Treatment</h4>
                                        </Zoom>
                                        <Link to="/hair-treatment-clinic-in-bandra"><button>  Know more </button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='serviceCard'>
                                    <div className='serviceCardImg'>
                                        <Swiper slidesPerView={1}
                                            spaceBetween={30}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, FreeMode, Pagination]}
                                            className="mySwiper">
                                            <SwiperSlide><img src={AntiAgeingTreatment} alt='Anti Ageing Treatment' /></SwiperSlide>
                                            <SwiperSlide><img src={YouthPreserve01} alt='best Anti Ageing Treatment' /></SwiperSlide>
                                            <SwiperSlide><img src={YouthPreserve02} alt='best Anti Ageing Treatment clinic' /></SwiperSlide>
                                        </Swiper>

                                    </div>
                                    <div className='serviceCardTitle'>
                                        <Zoom cascade>
                                            <h4>Youth Preserve Antiageing Treatment</h4>
                                        </Zoom>
                                        <Link to="/youth-preserve-antiaging-treatment-in-bandra" ><button>Know more </button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='serviceCard'>
                                    <div className='serviceCardImg'>
                                        <Swiper slidesPerView={1}
                                            spaceBetween={30}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, FreeMode, Pagination]}
                                            className="mySwiper">
                                            <SwiperSlide><img src={MaleHairLazer} alt='Male HairLazer' /></SwiperSlide>
                                            <SwiperSlide><img src={LaserHairRemoval01} alt='Male HairLazer treatment' /></SwiperSlide>
                                            <SwiperSlide><img src={LaserHairRemoval02} alt='Male HairLazer treatment clinic' /></SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='serviceCardTitle'>
                                        <Zoom cascade>
                                            <h4>Permanent Laser Hair Reduction</h4>
                                        </Zoom>
                                        <Link to="/laser-hair-reduction-treatment-in-bandra"><button>Know more </button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='serviceCard'>
                                    <div className='serviceCardImg'>
                                        <Swiper slidesPerView={1}
                                            spaceBetween={30}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, FreeMode, Pagination]}
                                            className="mySwiper">
                                            <SwiperSlide><img src={PigmentationTreatment} alt='Pigmentation Treatment' /></SwiperSlide>
                                            <SwiperSlide><img src={PigmentationTreatment01} alt='Pigmentation Treatment clinic' /></SwiperSlide>
                                            <SwiperSlide><img src={PigmentationTreatment02} alt='best Pigmentation Treatment clinic' /></SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='serviceCardTitle'>
                                        <Zoom cascade>
                                            <h4>Pigmentation Treatment</h4>
                                        </Zoom>
                                        <Link to="/pigmentation-treatment-in-bandra"><button>Know more </button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='serviceCard'>
                                    <div className='serviceCardImg'>
                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={30}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, FreeMode, Pagination]}
                                            className="mySwiper">
                                            <SwiperSlide><img src={EnhanceMediFacials} alt='Enhance MediFacials' /></SwiperSlide>
                                            <SwiperSlide><img src={EnhanceMediFacials01} alt='Enhance MediFacials clinic' /></SwiperSlide>
                                            <SwiperSlide><img src={EnhanceMediFacials02} alt='best EnhanceMedi Facials clinic' /></SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='serviceCardTitle'>
                                        <Zoom cascade>
                                            <h4>Enhance Medi Facials</h4>
                                        </Zoom>
                                        <Link to="/medi-facial-clinic-in-bandra"><button>Know more </button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='serviceCard'>
                                    <div className='serviceCardImg'>
                                        <Swiper slidesPerView={1}
                                            spaceBetween={30}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, FreeMode, Pagination]}
                                            className="mySwiper">
                                            <SwiperSlide><img src={AcneAndScareManagement} alt='Acne And Scare Management' /></SwiperSlide>
                                            <SwiperSlide><img src={AcneandScaremanagement01} alt='Acne And Scare Management clinic' /></SwiperSlide>
                                            <SwiperSlide><img src={AcneandScaremanagement02} alt='best Acne And Scare Management clinic' /></SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className='serviceCardTitle'>
                                        <Zoom cascade>
                                            <h4>Acne and Scar Management</h4>
                                        </Zoom>
                                        <Link to="/acne-scar-management-clinic-in-bandra"><button>Know more </button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Home