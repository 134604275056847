import React from "react";
import "./ServiceDetail/ServiceDetail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import { MetaTags } from 'react-meta-tags';
// images
import Result1 from "../Images/before-after-1.jpg";
import Result2 from "../Images/before-after-2.jpg";
import Result3 from "../Images/before-after-3.jpg";
import serviceImg from "../Images/serviceDetail-1.jpg";
import serviceImg2 from "../Images/serviceDetail-2.jpg";

import AcnePeel from "../Images/Service Main Image/Acne and Scare management.jpg";
import Microneedling from "../Images/Service Main Image/Microneedling.webp";
import MNRF from "../Images/Service Main Image/MNRF.jpeg";
import FractionalCO2Laser from "../Images/Service Main Image/Fractional CO2 Laser.jpeg";
import WartRemoval from "../Images/Service Main Image/Wart Removal.png";

const AcneAndScarManagement = () => {
  return (
    <div>

      <MetaTags>
        <title>Acne and Scar Management Clinic | Enhance Aesthetic Clinic</title>
        <meta title="Acne and Scar Management Clinic | Enhance Aesthetic Clinic" />
        <meta name="description" content="Visit our Acne and Scar Management Clinic for powerful answers to achieve clean and smooth pores and skin. Book your session now." />
        <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
        <link rel="canonical" href="https://enhanceaesthetic.in/acne-scar-management-clinic-in-bandra" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Acne and Scar Management Clinic | Enhance Aesthetic Clinic" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enhanceaesthetic.in/acne-scar-management-clinic-in-bandra" />
        <meta property="og:description" content="Visit our Acne and Scar Management Clinic for powerful answers to achieve clean and smooth pores and skin. Book your session now." />
        <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
      </MetaTags>

      {/* service detail home */}
      <div className="serviceDetailHome">
        <h1>Acne and Scar Management Clinic In Bandra</h1>
      </div>

      {/* service details */}
      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Acne Peel</h2>
                <p>
                  Our Acne Peel specially designed for acne prone skin and acne marks. It's a facial resurfacing treatment that uses a chemical solution to replace the outer, dead skin layer to reveal the underlying areas of fresher, younger looking skin .Different Peels like black peel, salicylic peel, INNO peel or yellow peel each having unique properties that help in different grades of acne, comedones or pigmentation are used. Black Peel has a unique combination of Black acetic acid, tetrahydro Jasmonic acid & Salicylic acid, Potassionm iodide & Bio sulphur which works as comedolytic, keratolytic, exfoliates skin and has anti-inflammatory effect. Inno Peel is a potent synergistic action peel containing vitamin A, lactic acid, arbutin, salicylic acid, kojic acid, and phytic acid used to treat acne, acne marks, and pigmentation, evens out skin tone leaving skin rejuvenated. Salicylic peel acts like an instant anti-inflammatory, anti-microbial, and anti-redness peel to reduce comedonal acne and acne marks.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={AcnePeel} alt="AcnePeel" />
              </div>
            </div>
          </div>
        </div>

        <div className="serviceDetails">
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-1 order-md-2">
                <div className="servcieDetailText">
                  <h2>Microneedling </h2>
                  <p>
                    Microneedling is a cosmetic procedure which involves pricking the skin with tiny sterilized needles using a dermapen or derma roller. The small wounds cause your body to make more collagen and elastin, which heal your skin and help you look younger. This is also called collagen induction therapy. Microneedling increases the absorption of hair and skin products used along with the procedure. It also improves blood supply to skin, improves skin texture
                  </p>
                  <p>
                    Microneedling helps with issues like Acne marks and scars, Hair loss, hyperpigmentation, Large pores, Reduced skin elasticity, Scars and Stretch marks, Sun damage, Fine lines and wrinkles.
                  </p>
                </div>
              </div>
              <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
                <div className="serviceDetailImg">
                  <img src={Microneedling} alt="Microneedling" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="serviceDetails">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="servcieDetailText">
                  <h2>MNRF</h2>
                  <p>
                    Designed for beautiful, firmer, tighter and healthier skin! The Microneedling and Radiofrequency (MNRF) treatment stimulates the body's production of collagen to remove wrinkles, acne scars, hyperpigmentation, stretch marks, etc., leaving the skin looking healthier.                  </p>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <div className="serviceDetailImg">
                  <img src={MNRF} alt="MNRF" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="serviceDetails">
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-1 order-md-2">
                <div className="servcieDetailText">
                  <h2>Fractional CO2 Laser</h2>
                  <p>
                    Fractional CO2 Laser, Is the laser treatment is used to get rid of wrinkles, enlarged pores, or dull skin by breaking down the skin tissue and allowing new skin to grow over the treatment area. This new technology of Fractional Laser Resurfacing can obtain results that are similar to that of deep chemical peels or ablative laser resurfacing. The technology utilises laser energy microbeams to penetrate and break down skin tissue through the epidermis. Your body will naturally heal and during the process, creates new, healthy tissue to replace the affected areas that have undergone treatment. Scars should be of a lighter colour and your skin texture will be more even, leaving no visible wounds. It helps in reducing facial wrinkles, skin irregularities, such as blemishes and acne scars, age spots and tightens the skin.
                  </p>
                </div>
              </div>
              <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
                <div className="serviceDetailImg">
                  <img src={FractionalCO2Laser} alt="FractionalCO2Laser" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="serviceDetails">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="servcieDetailText">
                  <h2>RF Cautery / Skin Tag, Wart Removal</h2>
                  <p>
                    RF cautery treatment is used for Unwanted skin lesions like skin tags, warts , This treatment can be removed easily and effectively. Electrocautery is an effective medical technology to remove these benign skin growths. This procedure uses heat from an electric current to destroy these unwanted lesions. Prior to this treatment a local anaesthetic will be administered, to ensure your comfort throughout the treatment. The procedure involves using a light electrical current to heat up the skin, in order to achieve tissue destruction in a very narrow and targeted location.
                  </p>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <div className="serviceDetailImg">
                  <img src={WartRemoval} alt="WartRemoval" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcneAndScarManagement;
