import React from "react";

import "./ServiceDetail/ServiceDetail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import { MetaTags } from 'react-meta-tags'; 
// images
import Result1 from "../Images/before-after-1.jpg";
import Result2 from "../Images/before-after-2.jpg";
import Result3 from "../Images/before-after-3.jpg";
import serviceImg from "../Images/serviceDetail-1.jpg";
import serviceImg2 from "../Images/serviceDetail-2.jpg";

import BotoxWrinkleTreatment from "../Images/B & A Images/Botox Wrinkle Treatment B&A 01.jpeg";
import Hyperhidrosis01 from "../Images/Service Main Image/Hyperhidrosis 01.jpg";
import JawlineSlimming from "../Images/Service Main Image/jawline.jpg";
import RestlaneDermalFillers from "../Images/B & A Images/Lip B&A 02.jpeg";
import D7HIFU from "../Images/Service Main Image/7D HIFU.jpg";
import Threadlift from "../Images/Service Main Image/threadlift.jpg";
import VoliteSkinQualityInjectable from "../Images/Service Main Image/glowbeauty.jpg";

const YouthPreserve = () => {
  return (
    <div>

      <MetaTags>
        <title>Youth Preserve Antiaging Treatment | Enhance Aesthetic Clinic</title>
        <meta title="Youth Preserve Antiaging Treatment | Enhance Aesthetic Clinic" />
        <meta name="description" content="Discover the powerful Youth Preserve Antiaging Treatment to rejuvenate your pores and skin and fight symptoms of getting old. Book Appointment Now!" />
        <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
        <link rel="canonical" href="https://enhanceaesthetic.in/youth-preserve-antiaging-treatment-in-bandra" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Youth Preserve Antiaging Treatment | Enhance Aesthetic Clinic" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enhanceaesthetic.in/youth-preserve-antiaging-treatment-in-bandra" />
        <meta property="og:description" content="Discover the powerful Youth Preserve Antiaging Treatment to rejuvenate your pores and skin and fight symptoms of getting old. Book Appointment Now!" />
        <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
      </MetaTags>


      {/* service detail home */}
      <div className="serviceDetailHome">
        <h1>Youth Preserve Antiageing Treatment</h1>
      </div>

      {/* service details */}
      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Botox Wrinkle Treatment</h2>
                <p>
                  BOTOX is quick, safe, FDA approved treatment to reduce appearance of dynamic wrinkles of the face like frown lines, crow's feet and forehead lines and visually turn back the hands of time without surgery. A quick 10 minute procedure with no downtime. Botulinum toxin BTX (Type A) is a purified protein that is administered in a simple, nonsurgical procedure to reduce the moderate to severe dynamic wrinkles. Thus it is the magic potion to erase those unwanted lines and wrinkles! BTX (Type A) is a purified protein It is no longer just used as an anti-ageing solution. It is also used to enhance certain facial features for people of any age group. We approach in a holistic manner and aim for natural and balanced results. BOTOX® and Xeomin® are some of the brands that we use for toxin injections.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/q23C--92UTo?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Hyperhidrosis</h2>
                <p>
                  Excessive sweating, beyond what is needed to control body temperature, is called hyperhidrosis and affects nearly 3 percent of the population. Most commonly, excessive sweating occurs in the underarms, hands, feet, forehead or groin. Hyperhidrosis can have a profound impact on daily life, making even simple tasks and interactions difficult.
                </p>
                <p>
                  Fortunately, there are options for treating excessive sweating. In the enhance aesthetic clinic our team evaluates your history of excessive sweating, provides a physical exam, and discusses the treatments that may be best for your type of sweating disorder
                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={Hyperhidrosis01} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Jawline Slimming</h2>
                <p>
                  A quick popular and accessible aesthetic treatment to provide a slimmer shape to the jaw that everyone desires. Our Jawline Slimming treatment uses anti-wrinkle injections to relax the muscles in your jaw, giving a naturally reduced jawline and correcting potential jawline asymmetry. JAW BOTOX is a popular non surgical procedure where specific uits of Botox are injected into Masseter muscle which reduces the size and tension of the masseter making your face appear slimmer, a quick 5 minute procedure, slimming the jawline and the face. Apart from jaw slimming, this procedure also helps patients having bruxism (teeth grinding) and TMD.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={JawlineSlimming} alt="Jawline Slimming" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Juvederm | Restlane Dermal Fillers</h2>
                <p>
                  Dermal Fillers treatment at Enhance Hair & Skin Aesthetic Clinic are USFDA Approved and made of cross-linked hyaluronic acid, a naturally occurring substance in the body which are used to diminish the signs of ageing and restore youthful volume and contours to the face. This highly popular procedure is minimally invasive with no downtime and completely rejuvenates the face.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/q23C--92UTo?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>7D HIFU</h2>
                <p>
                  7D HIFU (High Intensity Focused Ultrasound) uses concentrated ultrasound energy to the face lifting, wrinkle removal, and body Sculpting by a handheld device. It is a non-invasive treatment that helps stimulates the cells, resulting in tissue rejuvenation and collagen production. The overall effect of the treatment is to promote tightening and lifting of the skin in these areas. A HIFU treatment can help stimulate tissue rejuvenation for a pulled ack face.7D HIFU is suited to patients with skin laxity that is moderate or mild. High intensity focussed ultrasound facial or HIFU facial is a non invasive treatment for facial aging. 7D HIFU machine focused on the depth of the skin 3mm (dermis layer) and 4.5mm (SMAS layer). It generates continuous micro-thermal coagulation and the coagulated tissue shrinks as the phenomenon occurs. The reconstruction of collagen fibers will improve skin texture and lifting results. The 7D HIFU machine has a special 2.0mm cartridge which is specially applied to the eye surrounding treatments. (Eye bags, under-eye wrinkles, Nasal lines, mouth surroundings and lift the brow.) The technology is non-invasive and non-surgical. It works effectively for the face, eyes, neck, and body. Compared to traditional HIFU machines, 7D HIFU is completely safe, pain-free, and comfortable.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={D7HIFU} alt="7D HIFU" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Threadlift</h2>
                <p>
                  Thread lifts are Hollywoods secret to younger, tighter looking skin.
                </p>
                <p>
                  The non-surgical ThreadLift at Enhance Aesthetic Clinic is a perfect solution to enhance facial contours, lift sagging skin in the cheek, jowl, neck and around the eyebrow. We follow specific protocols of thread lifting considering Indian facial features. All thread lift procedures at Enhance Aesthetic Clinic are conducted by highly skilled doctors only under strict guidelines of hygiene and safety.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={Threadlift} alt="Threadlift" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Volite Skin Quality Injectable</h2>
                <p>
                  Juvederm Volite is one popular of Skin Booster filler. It is made of hyaluronic acid gel (just like typical dermal fillers) and is designed to improve the quality of your skin for 9 to 12 months. It is injected into the middle layers of the dermis to smoothen and nourish the skin from the inside out.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={VoliteSkinQualityInjectable} alt="Volite Skin Quality Injectable" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouthPreserve;
