import React from "react";
import "./ServiceDetail/ServiceDetail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import { MetaTags } from 'react-meta-tags';
// images
import Result1 from "../Images/before-after-1.jpg";
import Result2 from "../Images/before-after-2.jpg";
import Result3 from "../Images/before-after-3.jpg";
import serviceImg from "../Images/serviceDetail-1.jpg";
import serviceImg2 from "../Images/serviceDetail-2.jpg";

import EnhanceChemicalPeels from "../Images/Service Main Image/Enhance Brightning Facial.jpg";
import QSwitchNDYagLaser from "../Images/Service Main Image/Q Switch ND Yag Laser.jpg";
import GlutaglowIVDrip from "../Images/Service Main Image/Glutaglow IV Drip.jpg";

const Pigmentation = () => {
  return (
    <div>

      <MetaTags>
        <title>Pigmentation Treatment - Restore Even Skin Tone and Radiance</title>
        <meta title="Pigmentation Treatment - Restore Even Skin Tone and Radiance" />
        <meta name="description" content="Restore your skin's even tone and radiance with our effective Pigmentation Treatment. Say goodbye to dark spots and uneven skin tone." />
        <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
        <link rel="canonical" href="https://enhanceaesthetic.in/pigmentation-treatment-in-bandra" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Pigmentation Treatment - Restore Even Skin Tone and Radiance" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enhanceaesthetic.in/pigmentation-treatment-in-bandra" />
        <meta property="og:description" content="Restore your skin's even tone and radiance with our effective Pigmentation Treatment. Say goodbye to dark spots and uneven skin tone." />
        <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
      </MetaTags>


      {/* service detail home */}
      <div className="serviceDetailHome">
        <h1>Pigmentation Treatment</h1>
      </div>

      {/* service details */}
      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Enhance Chemical Peels</h2>
                <p> A chemical peel or chemoexfoliation is a facial resurfacing treatment that uses a chemical solution to replace the outer, dead layers of tired, blemished and wrinkled skin to reveal the underlying areas of fresher, younger looking skin. It also promotes the generation of collagen in the skin, speed up skin cell turnover essentially making them skin resurfacing and exfoliation agents. The skin that grows back is smoother. It is a great treatment option for treating uneven pigmentation, age spots, fine lines and wrinkles, fleckling, sun damaged skin, blemishes. Chemical peels come is various types and strength and choice of peel and number of sessions can be recommended depending on the indication and desired treatment. At Enhance Aesthetic Clinic we use only highest quality, medical grade peels in all its treatments. Lets have a look at some of our popular Chemical Peels at Enhance Aesthetic Clinic--</p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={EnhanceChemicalPeels} alt="Enhance Chemical Peels" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Q Switch ND Yag Laser </h2>
                <p><strong>Our Q-Switch ND:</strong> YAG laser is an advanced treatment for pigmentation, this laser emits a high-intensity beam of light at specific wavelengths (1064nm and 532nm) in very short pulses to destroy selective pigment cells. This allows effective treatment of the pigmentation disorders without damaging tissues by shortening laser exposure on the skin. It has the capability to target superficial skin lesions and also able to reach deeper layers of the skin tissue safely.</p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={QSwitchNDYagLaser} alt="Q Switch ND Yag Laser" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Glutaglow IV Drip </h2>
                <p>Have a special event or an important occasion coming up and want to look your best? Many of Hollywoods top stars get IV vitamin and antioxidant IV drips before their Red Carpet events to help them detoxify their bodies and look healthy and radiant.Glutathione reduces and even eliminates scars, acne, pigmentations, and even wrinkles. After the treatment, reduced dark circles under the eyes, removed dark spots and rejuvenated skin, being an effective anti-aging procedure. Free radicals and toxin buildup can lead to dull looking skin, hair, and nails. IV therapy naturally cleanses your body and removes toxins to give your skin a youthful and rejuvenated appearance. Our specially formulated IV drips having powerful antioxidants like Glutathione and Vitamin C help to replenish the body with essential vitamins and nutrients in a much more rapid manner than taking supplements to restore the natural glow to the skin. </p>
                <p>Advantages of IV drip includes- boosts immunity, replenishes nutrients, flushes out toxins, improves cellular health, combats free radical damage responsible for aging skin, improves skin tone and brightens skin to give a radiant glow.</p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={GlutaglowIVDrip} alt="Glutaglow IV Drip" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pigmentation;
