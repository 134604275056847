import './App.css';
import NavbarComp from './components/NavbarComp/NavbarComp';
import Home from './components/Home/Home.js';
import About from './components/About/About.js';
import Gallery from './components/Gallery/Gallery.js';
import BeforeAfter from './components/Before-After/BeforeAfter.js'
import Footer from './components/Footer/Footer.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Contact from './components/Contact/Contact.js';
import ServiceDetail from './components/ServiceDetail/ServiceDetail.js';
import Header from './components/Header/Header';
import HairTransplant from './components/HairTransplant';
import YouthPreserve from './components/YouthPreserve';
import LazerHairReduction from './components/LazerHairReduction';
import Pigmentation from './components/Pigmentation';
import EnhanceMediFacial from './components/EnhanceMediFacial';
import AcneAndScarManagement from './components/AcneAndScarManagement';
import ComBtn from './components/WhatApp/ComBtn';
import ServiceData from './components/ServiceDetail/ServiceData';
// import NavBar from './components/NavBar';
import ErrorPage from "./components/Error Page/ErrorPage";
function App() {

  const Bar = ServiceData.SubService
  return (
    <div>
      <BrowserRouter >
        {/* <NavbarComp /> */}
        {/* <NavBar /> */}
        <Header />
        <ComBtn />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutus-enhance-hair-and-skin-clinic-in-bandra' element={<About />} />
          <Route path='/hair-transplant-in-bandra' element={<BeforeAfter />} />
          <Route path='/clinic-gallery' element={<Gallery />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/hair-treatment-clinic-in-bandra' element={<HairTransplant />} />
          <Route path='/youth-preserve-antiaging-treatment-in-bandra' element={<YouthPreserve />} />
          <Route path='/laser-hair-reduction-treatment-in-bandra' element={<LazerHairReduction />} />
          <Route path='/pigmentation-treatment-in-bandra' element={<Pigmentation />} />
          <Route path='/medi-facial-clinic-in-bandra' element={<EnhanceMediFacial />} />
          <Route path='/acne-scar-management-clinic-in-bandra' element={<AcneAndScarManagement />} />
          <Route path='/*' element={<ErrorPage />} />

          {/* ///////////// */}

          {
            Bar.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.Path}
                    element={<ServiceDetail />}
                  />
                </>
              )
            })
          }


        </Routes>
        <Footer />
      </BrowserRouter  >
    </div>
  );

}

export default App;
