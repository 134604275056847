import React, { useState } from 'react';
import './ServiceDetail.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import { useLocation } from 'react-router-dom';
// images 
//////HairLossBA01 /////
import HairLossBA01 from "../../Images/B & A Images/Hair Loss B&A 01.jpeg";
import HairLossBA02 from "../../Images/B & A Images/Hair Loss B&A 02.jpeg";
import HairLossBA03 from "../../Images/B & A Images/Hair Loss B&A 03.jpeg";
import HairLossBA04 from "../../Images/B & A Images/Hair Loss B&A 04.jpeg";
import {
    menuItems
} from "../../menuItems";

import ServiceData from "../ServiceDetail/ServiceData";
import { MetaTags } from 'react-meta-tags';


// import

const ServiceDetail = () => {

    const ServiceImage = {
        height: "380px",

    }

    const search = useLocation();
    const Path = search.pathname;

    console.log(Path)


    // const findnumber = menuItems[2];
    // console.log(findnumber)\

    // menuItems.forEach((employee, index) => {

    //     const EventFdata = employee.find(e => e.id == id)
    //     console.log(EventFdata)

    // });

    // // const EventFdata = ServiceData.find(e => e.id == id)
    // const [edata, setEdata] = useState(ServiceData)

    // for (var i = 0; i <= edata.length;i++){
    //     if(edata[i].id == id){
    //         return edata[i]
    //     }
    //     else{
    //         console.log("error  ")
    //     }
    // }

    ///////////// 

    const findData = ServiceData.SubService

    const finalData = findData.find((e) => e.Path == Path);
    // console.log('findData', finalData)



    return (
        <div>

            <MetaTags>
                <title>{finalData.MetaTitle}</title>
                <meta title={finalData.MetaTitle} />
                <meta name="description" content={finalData.MetaDescription} />
                <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment,fue hair transplant clinic in bandra" />
                <link rel="canonical" href={finalData.MetaCanonical} />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content={finalData.MetaTitle} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={finalData.MetaCanonical} />
                <meta property="og:description" content={finalData.MetaDescription} />
                <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
            </MetaTags>

            {/* service detail home */}
            <div className='serviceDetailHome'>
                <h1>{finalData.Servicehead}</h1>
            </div>


            {/* service details */}
            <div className='container'>
                <div className='serviceDetails'>
                    <div className='IntroPara'>
                        <p>{finalData.Intro1}</p>
                        <p>{finalData.Intro2}</p>
                        <p>{finalData.Intro3}</p>
                    </div>
                    <h2>{finalData.ServiceName}</h2>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='servcieDetailText'>
                                <p>{finalData.Parra1}</p>
                                <p>{finalData.Parra2}</p>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <div className='serviceDetailImg'>
                                {finalData.SImage}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='IconAndPoints'>
                    <h4>{finalData.BenefitsHead}</h4>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='BENEFITS-Deatils'>
                                <div className='BENEFITS-icons-con'>
                                    <div className='BENEFITS-icons'>
                                        {finalData.NEXTGENERATIONTHERAPY}
                                    </div>
                                </div>
                                <div className='BENEFITS-text'>
                                    <h4>{finalData.BenefitsTitle1}</h4>
                                    <p>{finalData.BenefitsDes1}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='BENEFITS-Deatils'>
                                <div className='BENEFITS-icons-con'>
                                    <div className='BENEFITS-icons'>
                                        {finalData.QUALITY}
                                    </div>
                                </div>
                                <div className='BENEFITS-text'>
                                    <h4>{finalData.BenefitsTitle2}</h4>
                                    <p>{finalData.BenefitsDes2}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='BENEFITS-Deatils'>
                                <div className='BENEFITS-icons-con'>
                                    <div className='BENEFITS-icons'>
                                        {finalData.EFFICACY}
                                    </div>
                                </div>
                                <div className='BENEFITS-text'>
                                    <h4>{finalData.BenefitsTitle3}</h4>
                                    <p>{finalData.BenefitsDes3}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='BENEFITS-Deatils'>
                                <div className='BENEFITS-icons-con'>
                                    <div className='BENEFITS-icons'>
                                        {finalData.CONVENIENCE}
                                    </div>
                                </div>
                                <div className='BENEFITS-text'>
                                    <h4>{finalData.BenefitsTitle4}</h4>
                                    <p>{finalData.BenefitsDes4}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='BENEFITS-Deatils'>
                                <div className='BENEFITS-icons-con'>
                                    <div className='BENEFITS-icons'>
                                        {finalData.SAFETY}
                                    </div>
                                </div>
                                <div className='BENEFITS-text'>
                                    <h4>{finalData.BenefitsTitle5}</h4>
                                    <p>{finalData.BenefitsDes5}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='BENEFITS-Deatils'>
                                <div className='BENEFITS-icons-con'>
                                    <div className='BENEFITS-icons'>
                                        {finalData.STABILITY}
                                    </div>
                                </div>
                                <div className='BENEFITS-text'>
                                    <h4>{finalData.BenefitsTitle6}</h4>
                                    <p>{finalData.BenefitsDes6}</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                <div className='pointandHeading'>
                    <h6>
                        {finalData.PointHeading}
                    </h6>
                    <p>
                        {finalData.Pint01}
                    </p>
                    <p>
                        {finalData.Pint02}
                    </p>
                    <p>
                        {finalData.Pint03}
                    </p>
                    <p>
                        {finalData.Pint04}
                    </p>
                    <p>
                        {finalData.Pint05}
                    </p>
                    <p>
                        {finalData.Pint06}
                    </p>
                    <p>
                        {finalData.Pint07}
                    </p>
                    <p>
                        {finalData.Pint08}
                    </p>
                    <p>
                        {finalData.Pint09}
                    </p>
                    <p>
                        {finalData.Pint10}
                    </p>
                    <p>
                        {finalData.Pint11}
                    </p>
                </div>

                <div className='reamin-serviceData-Para'>
                    <p>{finalData.Parra3}</p>
                    <p>{finalData.Parra4}</p>
                    <p>{finalData.Parra5}</p>
                    <p>{finalData.Parra6}</p>
                    <p>{finalData.Parra7}</p>
                    <p>{finalData.Parra8}</p>
                    <p>{finalData.Parra9}</p>
                    <p>{finalData.Parra10}</p>
                    <p>{finalData.Parra11}</p>
                    <p>{finalData.Parra12}</p>
                    <p>{finalData.Parra13}</p>
                    <p>{finalData.Parra14}</p>
                </div>
                <div className='QandA-Section'>
                    <h5>{finalData.Question01}</h5>
                    <p>{finalData.Answers01}</p>
                    <p>{finalData.Answers0100}</p>
                    <p>{finalData.Answers0101}</p>
                    <p>{finalData.Answers0102}</p>
                    <p>{finalData.Answers0103}</p>

                    <h5>{finalData.Question02}</h5>
                    <p>{finalData.Answers02}</p>
                    <p>{finalData.Answers0200}</p>
                    <p>{finalData.Answers0201}</p>
                    <p>{finalData.Answers0203}</p>
                    <p>{finalData.Answers0204}</p>

                    <h5>{finalData.Question03}</h5>
                    <p>{finalData.Answers03}</p>
                    <p>{finalData.Answers0300}</p>
                    <p>{finalData.Answers0301}</p>
                    <p>{finalData.Answers0302}</p>
                    <p>{finalData.Answers0303}</p>

                    <h5>{finalData.Question04}</h5>
                    <p>{finalData.Answers04}</p>
                    <p>{finalData.Answers0400}</p>
                    <p>{finalData.Answers0401}</p>
                    <p>{finalData.Answers0402}</p>
                    <p>{finalData.Answers0403}</p>

                    <h5>{finalData.Question05}</h5>
                    <p>{finalData.Answers05}</p>
                    <p>{finalData.Answers05}</p>
                    <p>{finalData.Answers05}</p>
                    <p>{finalData.Answers05}</p>
                    <h5>{finalData.Question06}</h5>
                    <p>{finalData.Answers06}</p>
                    <p>{finalData.Answers06}</p>
                    <p>{finalData.Answers06}</p>
                    <p>{finalData.Answers06}</p>
                    <h5>{finalData.Question07}</h5>
                    <p>{finalData.Answers07}</p>
                    <p>{finalData.Answers07}</p>
                    <p>{finalData.Answers07}</p>
                    <p>{finalData.Answers07}</p>
                    <h5>{finalData.Question08}</h5>
                    <p>{finalData.Answers08}</p>
                    <p>{finalData.Answers08}</p>
                    <p>{finalData.Answers08}</p>
                    <p>{finalData.Answers08}</p>
                </div>

                <div className='endpara'>
                    <p>
                        {finalData.Parra001}
                    </p>

                </div>

                {/* service imges/ before after */}
                <div className='beforeAfterImages'>
                    <div className='beforeAfterGallery'>
                        <div className='videoTestimonals'>
                            <div className='videoTestimonalHead'>
                                <h2>{finalData.BATitle}</h2>
                            </div>
                            <div className='VideoTestimonial-con'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA1}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA2}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA3}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA4}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA5}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA6}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA7}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 d-flex justify-content-center'>
                                        <div className='Service-BA-Con'>
                                            {finalData.BA8}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceDetail;