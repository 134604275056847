import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../Images/logo.png';
import {
    menuItems
} from "../../menuItems";
import MenuItems from "../MenuItems";
import '../NavBar.css';

const Header = () => {

    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 70) {
            setColor(true)
        }
        else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <div>
            <Navbar className={color ? "navbar navbarScroll" : "navbar"} collapseOnSelect expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand >
                        <Link to="/">
                            <div className={color ? "navbar-logo navbar-logo-scroll" : "navbar-logo"}>
                                <img src={Logo} alt="Enhance Aesthetic clinic"/>
                            </div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {/* <Nav.Link href="#features">Home</Nav.Link>
                            <Nav.Link >About us</Nav.Link> */}
                            {/* <Nav.Link > */}
                            {/* <ul className="menus" > {
                                    menuItems.map((menu, index) => {


                                        const depthLevel = 0;
                                        return <MenuItems items={menu}
                                            key={index}
                                            depthLevel={depthLevel}
                                        />;
                                        <li className="menu-items" key={index}>
                                            <Link to={menu.path}>{menu.title}</Link>
                                        </li>

                                    })
                                }
                                </ul> */}
                            <ul className="menus">
                                {menuItems.map((menu, index) => {
                                    const depthLevel = 0;
                                    return (
                                        <MenuItems
                                            items={menu}
                                            key={index}
                                            depthLevel={depthLevel}
                                        />
                                    );
                                })}
                            </ul>
                        </Nav>
                        <Nav>
                            <Nav.Link href="tel:+918575099099">Phone: +91 8575 099 099</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div >
    )
}

export default Header;