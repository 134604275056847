import React, { useState } from 'react';
import './Gallery.css';
import Model from './Model.jsx';
import GalleryData from './Gallerydata.jsx';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
import { MetaTags } from 'react-meta-tags';


const Gallery = () => {

    const [clickImage, setClickImage] = useState(null)
    const [cureentIndex, setCureentIndex] = useState(null)

    const handelClick = (item, index) => {
        setCureentIndex(index);
        setClickImage(item.GImage);
        // console.log(clickImage)
        // console.log(cureentIndex)
    };

    // const some = GalleryData.length
    // console.log(some)

    const handelRotationRight = () => {
        const totallength = GalleryData.length;

        if (cureentIndex + 1 >= totallength) {
            setCureentIndex(0);
            const newurl = GalleryData[0].GImage;
            setClickImage(newurl)
            return;
        };
        const newIndex = cureentIndex + 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].GImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totallength = GalleryData.length;
        if (cureentIndex === 0) {
            setCureentIndex(totallength - 1);
            const newUrl = GalleryData[totallength - 1].GImage;
            setClickImage(newUrl);
        }
        const newIndex = cureentIndex - 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].GImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    return (
        <div>

            <MetaTags>
                <title>Clinic Gallery - Enhance Aesthetic Clinic</title>
                <meta title="Clinic Gallery - Enhance Aesthetic Clinic" />
                <meta name="description" content="Explore our best hair and skin clinic gallery at Enhance Aesthetic Clinic and witness stunning transformations. Book Your Appointment Now!" />
                <meta name="keywords" content="Best hair transplant in mumbai,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
                <link rel="canonical" href="https://enhanceaesthetic.in/clinic-gallery" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Clinic Gallery - Enhance Aesthetic Clinic" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://enhanceaesthetic.in/clinic-gallery" />
                <meta property="og:description" content="Explore our best hair and skin clinic gallery at Enhance Aesthetic Clinic and witness stunning transformations. Book Your Appointment Now!" />
                <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
            </MetaTags>

            {/* Gallery Home */}
            <div className='galleryHome'>
                <h1>Gallery</h1>
            </div>

            {/* Gallery Images */}
            <section className='Image-Gallery'>
                {/* <div className='Image-Gallery-title'>
                    <h4>Image gallery</h4>
                    <h3>Image gallery</h3>
                </div> */}
                <div className='container' style={{ marginBottom: "30px" }}>
                    <div className='row'>
                        {
                            GalleryData.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center align-item-center'>
                                            {/* <Zoom> */}
                                            <div className='G-Images' key={index}>
                                                <img src={item.GImage} alt="best skin tratment" onClick={() => handelClick(item, index)} />
                                            </div>
                                            {/* </Zoom> */}
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {clickImage && (
                <Model
                    clickImage={clickImage}
                    handelRotationRight={handelRotationRight}
                    setClickImage={setClickImage}
                    handelRotationLeft={handelRotationLeft}
                />
            )}


            <section className='Gallery-Section-2'>
                <div className='container' style={{ marginBottom: "30px" }}>
                    <div className='Gallery-Section-youtube'>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Pagination]}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/RMVSNTg4oG4?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8FSJgnVkDco?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Qfo04BBlPlk?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/v8Ij5FcPIf4?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kDtGX9y1sK8?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Du3dmaFih2M?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Ru_wXzO8nNg?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/q23C--92UTo?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/fIoYVbmaliM?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/a-CgDfE7ltw?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='youtubeFrame d-flex justify-content-center'>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/dcRrswu5xvk?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                  </div>
                            </SwiperSlide>

                        </Swiper>

                    </div>

                </div>

            </section>

        </div>
    )
}

export default Gallery