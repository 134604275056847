import React, { useState, useLayoutEffect } from "react";
import "./Facilities.css";
//////
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import logo from "../../Images/logo.png";

// Images
import HairRestoation01 from "../../Images/B & A Images/FUEHair Transplant B&A 01.jpeg";
import HairRestoation02 from "../../Images/B & A Images/FUEHair Transplant B&A 02.jpeg";
import HairRestoation03 from "../../Images/B & A Images/FUEHair Transplant B&A 03.jpeg";
import HairRestoation04 from "../../Images/B & A Images/FUEHair Transplant B&A 04.jpeg";
import HairRestoation18 from "../../Images/B & A Images/FUEHair Transplant B&A 05.jpeg";
import HairRestoation05 from "../../Images/B & A Images/Hair Loss B&A 01.jpeg";
import HairRestoation06 from "../../Images/B & A Images/Hair Loss B&A 02.jpeg";
import HairRestoation07 from "../../Images/B & A Images/Hair Loss B&A 03.jpeg";
import HairRestoation08 from "../../Images/B & A Images/Hair Loss B&A 04.jpeg";
import HairRestoation09 from "../../Images/B & A Images/Hair Loss B&A 05.jpeg";
import HairRestoation10 from "../../Images/B & A Images/Hair Loss B&A 06.jpeg";
import HairRestoation11 from "../../Images/B & A Images/Hair Loss B&A 07.jpeg";
import HairRestoation12 from "../../Images/B & A Images/Hair Loss B&A 08.jpeg";
import HairRestoation13 from "../../Images/B & A Images/Hair Loss B&A 09.jpeg";
import HairRestoation14 from "../../Images/B & A Images/Hair Loss B&A 10.jpeg";
import HairRestoation15 from "../../Images/B & A Images/Hair Loss B&A 11.jpeg";
import HairRestoation16 from "../../Images/B & A Images/Hair Loss B&A 12.jpeg";
import HairRestoation17 from "../../Images/B & A Images/Hair Loss B&A 13.jpeg";
import HairRestoation19 from "../../Images/B & A Images/Hair Loss B&A 14.jpeg";
import HairRestoation20 from "../../Images/B & A Images/Hair Loss B&A 15.jpeg";


import OtherAesthetics01 from "../../Images/B & A Images/BOTOX FILLER BEFORE AFTER 01.jpeg";
import OtherAesthetics02 from "../../Images/B & A Images/Acne and Scare management 02.jpeg";
import OtherAesthetics03 from "../../Images/B & A Images/Beard and Moustache Hair Transplant B&A 01.jpeg";
import OtherAesthetics04 from "../../Images/B & A Images/Beard and Moustache Hair Transplant B&A 02.jpeg";
import OtherAesthetics05 from "../../Images/B & A Images/Beard and Moustache Hair Transplant B&A 03.jpeg";
import OtherAesthetics06 from "../../Images/B & A Images/Botox Wrinkle Treatment B&A 01.jpeg";
import OtherAesthetics07 from "../../Images/B & A Images/Botox Wrinkle Treatment B&A 02.jpeg";
import OtherAesthetics08 from "../../Images/B & A Images/Botox Wrinkle Treatment B&A 03.jpeg";
import OtherAesthetics09 from "../../Images/B & A Images/Eyebrow Hair Transplant B & A 01.jpeg";
import OtherAesthetics10 from "../../Images/B & A Images/Eyebrow Hair Transplant B & A 02.jpeg";
import OtherAesthetics11 from "../../Images/B & A Images/Eyebrow Hair Transplant B & A 03.jpg";
import OtherAesthetics18 from "../../Images/B & A Images/Lip B&A 01.jpeg";
import OtherAesthetics19 from "../../Images/B & A Images/Lip B&A 02.jpeg";
import OtherAesthetics20 from "../../Images/B & A Images/PIGMENTATION BEFORE AFTER.jpeg";
import OtherAesthetics21 from "../../Images/B & A Images/Hair Loss B&A 05.jpeg";

import Zoom from "react-reveal/Zoom";

const Facilities = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  ///////
  const [key, setKey] = useState("home");

  ///////
  return (
    <>

      {/* /////////////////////////////// section 2 /////////////////////////////////// */}

      <section className="FacilitiesSection2">
        <div className="FacilitiesSection2-heading">
          <Zoom>
            {/* <h2>Our Specialty Services</h2> */}
          </Zoom>
        </div>
        <div className="Container">
          <div className="Facilities-Section2-tabpanel">
            <Tabs
              defaultActiveKey="Treatment"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="Treatment" title="Hair Restoration">
                <div className="Treatment-Con">
                  {/* <div className="container"> */}
                    <div className="row">                  
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation02} alt="Hair transplant Clinic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img
                                src={HairRestoation03}
                                alt="Aesthetic Clinic Hair Transplant"
                              />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation04} alt="Hair Transplant" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation18} alt="Hair Transplant" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation20} alt="Hair Transplant" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation05} alt="best Hair Transplant clinic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation06} alt="Best hair transplant in mumbai" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation07} alt="Best hair transplant clinic in mumbai" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation08} alt="Hair transplant in bandra" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation09} alt="Laser hair removal in Bandra" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation10} alt="Best hair and skin clinic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation11} alt="Best hair clinic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation12} alt="Best skin clinic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation13} alt="Chemical peel treatment" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation14} alt="Hair thinning treatment" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation15} alt="Anti ageing treatment" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation16} alt="PRP treatment" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation17} alt="Enhance Clinic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics03} alt="Enhance Aesthetic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics04} alt="Enhance hair and skin" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation01} alt="Hair Transplant result" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={HairRestoation19} alt="Hair Transplant result" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                    </div>
                  {/* </div> */}
                </div>
              </Tab>
              <Tab eventKey="Vaccination" title="Other Aesthetic Treatments">
                <div className="Treatment-Con">
                  {/* <div className="container"> */}
                    <div className="row">
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics06} alt="Dr Ankur Saha" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics07} alt="Best hair loss clinic " />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics08} alt="Best hair transplant clinic" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics09} alt="Best hair transplant in mumbai" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics10} alt="Hair transplant in bandra" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics11} alt="Best hair transplant doctor " />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics18} alt="Hair loss treatment" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics19} alt="Hairfall treatment" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics20} alt="Male pattern baldness" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics21} alt="Laser hair removal in Bandra" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 d-flex justify-content-center align-item-center">
                        <div className="TabPalnel-Images">
                          <Zoom>
                            <div className="TP-Image">
                              <img src={OtherAesthetics01} alt="Carbon Laser facial" />
                            </div>
                          </Zoom>
                        </div>
                      </div>
                    </div>
                  {/* </div> */}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
      {/* /////////////////// */}
    </>
  );
};

export default Facilities;
