import Image01 from "../../Images/icon-awards.jpeg";
import Image2 from "../../Images/dr-ankur-award.jpeg";
import Image3 from "../../Images/gallery/dr-ankur-arjun-rampal.jpg";
import Image4 from "../../Images/gallery/dr-ankur-with-celeb.jpg";
import Image5 from "../../Images/gallery/dr-ankur-2.jpg";
import Image6 from "../../Images/gallery/dr-ankur-3.jpg";
import Image7 from "../../Images/gallery/dr-ankur-4.jpg";
import Image8 from "../../Images/gallery/dr-ankur-aesthetic-clinic.jpg";
import Image9 from "../../Images/gallery/dr-ankur-clinic.jpg";
import Image10 from "../../Images/About Doctor.jpg";
import Image11 from "../../Images/about Us.jpg";
import Image12 from "../../Images/Service Main Image/7D HIFU.jpg";
import Image13 from "../../Images/Service Main Image/Acne Facial Peel.jpg";
import Image14 from "../../Images/enhance-clinic.jpeg";
import Image15 from "../../Images/Service Main Image/Celebrity Vampire Facial.jpg";
import Image16 from "../../Images/Service Main Image/EnhanceMediFacial 2.jpg";
import Image17 from "../../Images/Service Main Image/Groth Factor Concentrate.jpg";
import Image18 from "../../Images/Service Main Image/pigmentation.jpg";
import Image19 from "../../Images/Service Main Image/Platelet Rich Plasma service.jpg";
import Image20 from "../../Images/Service Main Image/Platelet Rich Plasma.jpg";
import Image21 from "../../Images/Service Main Image/Superjet and Infuse Facial.jpg";
import Image22 from "../../Images/Service Main Image/Zo obagi Red Carpet Facial.jpg";
import Image23 from "../../Images/gallery/gallery Image 24.jpg";
import Image24 from "../../Images/gallery/Gallery Image 25.jpg";
import Image25 from "../../Images/gallery/Gallery Image 26.jpg";


const GalleryData = [
    {
        id: 1,
        GImage:Image01,
    },
    {
        id: 2,
        GImage:Image2,
    },
    {
        id: 3,
        GImage:Image3,
    },
    {
        id: 4,
        GImage:Image4,
    },
    {
        id: 5,
        GImage:Image5,
    },
    {
        id: 6,
        GImage:Image6,
    },
    {
        id: 7,
        GImage: Image7,
    },
    {
        id: 8,
        GImage: Image8,
    },
    {
        id: 9,
        GImage: Image9,
    },
    {
        id: 10,
        GImage: Image10,
    },
    {
        id: 11,
        GImage: Image11,
    },
    {
        id: 12,
        GImage: Image12,
    },
    {
        id: 13,
        GImage: Image13,
    },
    {
        id: 14,
        GImage: Image14,
    },
    {
        id: 15,
        GImage: Image15,
    },
    {
        id: 16,
        GImage: Image16,
    },
    {
        id: 17,
        GImage: Image17,
    },
    {
        id: 18,
        GImage: Image18,
    },
    {
        id: 19,
        GImage: Image19,
    },
    {
        id: 20,
        GImage: Image20,
    },
    {
        id: 21,
        GImage: Image21,
    },
    {
        id: 22,
        GImage: Image22,
    },
    {
        id: 23,
        GImage: Image23,
    },
    {
        id: 24,
        GImage: Image24,
    },
    {
        id: 25,
        GImage: Image25,
    },
];
export default GalleryData;