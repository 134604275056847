import React from "react";
import "./ServiceDetail/ServiceDetail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import { MetaTags } from 'react-meta-tags';
// images
import Result1 from "../Images/before-after-1.jpg";
import Result2 from "../Images/before-after-2.jpg";
import Result3 from "../Images/before-after-3.jpg";
import serviceImg from "../Images/serviceDetail-1.jpg";
import serviceImg2 from "../Images/serviceDetail-2.jpg";

import CelebrityVampireFacialBA01 from "../Images/Service Main Image/Zo obagi Red Carpet Facial.jpg";
import CarbonLaserFacial from "../Images/Service Main Image/Carbon Laser Facial.jpg";
import D7HIFUFacial from "../Images/serviceDetail-1.jpg";
import AcneFacialPeel from "../Images/Service Main Image/Acne and Scare management.jpg";
import MicrodermabrasionSkinPolishing from "../Images/Service Main Image/MicrodermabrasionSkinPolishing.jpg";
import SuperjetandInfuseFacial from "../Images/Service Main Image/Superjet and Infuse Facial.jpg";
import PumpkinFacial from "../Images/Service Main Image/pumpkin-mask.jpg";
import CarbonLaserFacialBA from "../Images/Service Main Image/pigmentation.jpg";
import EnhanceBridalCareandGroomCare from "../Images/serviceDetail-2.jpg";
import CollagenInductionFacial from "../Images/Service Main Image/Collagen Induction Facial.jpg";
import ZoobagiRedCarpetFacial from "../Images/Service Main Image/Zo obagi Red Carpet Facial main Service.webp";


const EnhanceMediFacial = () => {
  return (
    <div>

      <MetaTags>
        <title>Medi Facial Clinic in Bandra | Enhance Aesthetic Clinic</title>
        <meta title="Medi Facial Clinic in Bandra | Enhance Aesthetic Clinic" />
        <meta name="description" content="Discover the first-rate Medi Facial Clinic in Bandra for professional pores and skin revitalization treatments. Achieve a radiant and younger complexion." />
        <meta name="keywords" content="Best hair transplant in Bandra,Hair transplant in bandra,Laser hair removal in Bandra,Best hair and skin clinic,Chemical peel treatment,Hair thinning treatment,Anti ageing treatment,PRP treatment" />
        <link rel="canonical" href="https://enhanceaesthetic.in/medi-facial-clinic-in-bandra" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Medi Facial Clinic in Bandra | Enhance Aesthetic Clinic" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enhanceaesthetic.in/medi-facial-clinic-in-bandra" />
        <meta property="og:description" content="Discover the first-rate Medi Facial Clinic in Bandra for professional pores and skin revitalization treatments. Achieve a radiant and younger complexion." />
        <meta property="og:image" content="https://enhanceaesthetic.in/static/media/logo.5902c4a5aac48752bd30.png" />
      </MetaTags>


      {/* service detail home */}
      <div className="serviceDetailHome">
        <h1>Medi Facial Clinic in Bandra</h1>
      </div>

      {/* service details */}
      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>Celebrity Vampire Facial</h2>
                <p>
                  The celebrity loved 360-degree facial rejuvenation treatment involving our customised PRP Protocols.True to its name, the vampire facial uses components of the patients blood called PRP (platelet rich plasma) and injects these via microneedling into the skin as part of the treatment. These contain growth factors that stimulate healing of the skin. This treatment is suited to managing wrinkles and sun damage.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={CelebrityVampireFacialBA01} alt="Celebrity Vampire Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Carbon Laser Facial </h2>
                <p>
                  Today irrespective of any filed everyone wants his/her skin to be perfect and when it comes to face one becomes more conscious. There are many products in the market which helps you to get glowing skin. But before using it, sometimes we don’t think once about side effects. That lay us into miserable condition and harm our skin severely.
                </p>
                <p>
                  Due to improper use of different face products skin gets enduringly damage. Now it’s easy to say yes to skin treatment and blooming skin as others with– “Carbon Peel”. This is known as “Hollywood Peel” also. A Carbon Peel is a revolutionary leaser treatment that is completely painless with in minimal time span. It is highly suitable for the people who have oily skin, blackheads, dull skin, acne & enlarged pores. This is an excellent medium to exfoliate and refresh your skin. Along with that facial skin become softer, smother and finer.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={CarbonLaserFacial} alt="CarbonLaser Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="servcieDetailText">
                <h2>7D HIFU Facial</h2>
                <p>
                  This advanced facial uses the latest in ultrasound techniques to penetrate into the lower layers of skin for healing and rejuvenation. HIFU is suited to patients with skin laxity that is moderate or mild. High intensity focussed ultrasound facial or HIFU facial is a non invasive treatment for facial aging. HIFU uses ultrasound technology to penetrate in the deeper layers of the skin and cause collagen remodelling which leads to skin tightenin
                </p>
                <p>
                  Steps --Cleansing, Exfoliation, massage, Numbing cream 30min, HIFU, Mask
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={D7HIFUFacial} alt="D7HIFU Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Acne Facial/ Peel</h2>
                <p>
                  Specialy designed for acne prone skin and acne marks, this treatment includes chemical peels like black peel, salicylic peel, INNO peel or yellow peel each having unique properties that help in different grades of acne, comedones or pigmentation. BLack peel has Black acetic acid, tetrahydro Jasmonic acid & Salicylic acid, Potassionm iodide & Bio sulphur which works as comedolytic, keratolytic, exfoliates skin and has anti-inflammatory effect. Microneedling is done whenever required for acne marks and scars. This treatment involves using High Frequency with acne jelly it has germicidal effect. Last step is to apply our acne mask to settle redness and soothe the skin.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={AcneFacialPeel} alt="Acne Facial Peel" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="servcieDetailText">
                <h2> Microdermabrasion / Skin Polishing</h2>
                <p>
                  Skin polishing is a treatment where the skin is polished and nourished with nutrients, while the dead skin is removed. It is a gentle procedure and similar to a facial. This process includes using the crystal on your skin to gently exfoliate the uppermost dead layer of the skin, promote cell and collagen production, and also increase the blood circulation making your skin look younger, smoother, and fresher. Stimulation of skin cells and collagen minimizes the appearances of skin pores too. The process is a quick one and takes just as much as time as you would to finish your lunch.
                </p>
              </div>
            </div>
            <div className="col-md-6   d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={MicrodermabrasionSkinPolishing} alt="Microdermabrasion Skin Polishing" />
              </div>
            </div>
          </div>
        </div>
      </div>





      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Superjet and Infuse Facial</h2>
                <p>
                  Advanced aesthetic technology-based, this comprehensive facial is another signature treatment. As the name suggests, it helps to infuse essential nutrients deep into the skin to brighten, hydrate, and rejuvenate it. A high-speed micro-jet spay is used to accelerate a jet of micro droplets to gently cleanse and exfoliate. Next, an oxygen water spray delivers pulsated oxygen with a specially formulated serum containing arginine, vitamin C, and kojic acid to further exfoliate. Then, to improve the skin texture, a gentle peel can be applied containing a combination lactic acid, arginine, arbutin, kojic acid. Post this, natural aloe vera is applied with amino acids, minerals, and vitamins. Next, we use electroporation to enhance the permeability of the skin tissue so that it can absorb the aloe vera completely; electro-osmosis allows the serum to deeply penetrate the skin. Next, a cold hammer is used to calm the skin and seal in the serum infusions. Finally, a hydrating and natural brightening mask is applied for one final brightening effect on the face.                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={SuperjetandInfuseFacial} alt="Superjet and Infuse Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>






      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="servcieDetailText">
                <h2>Pumpkin Facial</h2>
                <p>
                  Inspired by science of Korean Glass Skin, this deep nourishing peel helps to remove dead keratinized skin layer, and is good for cell renewal in dry, hyperpigmented, aging and acne prone skin. This facial utilises the Vitamin A derivatives effecting on the skin's retinoic acid receptor, fermented enzymes and salicylic acid for skin peeling and helps in improving the texture, clarity, and smoothness of the skin.                </p>
              </div>
            </div>
            <div className="col-md-6   d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={PumpkinFacial} alt="Pumpkin Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>







      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Enhance Photofacial</h2>
                <p>
                  Photofacial is a popular non-invasive cosmetic treatment that uses laser or photodynamic therapy to improve overall skin tone. Also known as photorejuvenation, this treatment helps promote collagen production, reduces pigmentation and tanning, and gives your skin a more even complexion. When the Q-Switch laser is used to treat your pigmented lesions including sunspots, age spots, freckle, etc. it targets the melanin in the superficial layer of your skin. When the pigmented lesion absorbs the light energy from the Q-Switch Laser it gets converted into heat. This process destroys the target without harming the surrounding skin, giving rejuvenated look.                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={CarbonLaserFacialBA} alt="Carbon Laser Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>









      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="servcieDetailText">
                <h2>Enhance Bridal Care and Groom Care</h2>
                <p>
                  You're engaged, congratulations! As a bride-to-be and groom-to-be, you already know the joy and stresses of planning a wedding. While everything else falls into place, its time for you to take care of yourself, your hair and skin in preparation for the big day and need someone you can trust implicitly with your hair and skin! Starting your hair and skin care regime few months prior to your wedding date will allow us to cater to every one of your unique hair and skin care needs and see each treatment through to full completion. Popular pre-wedding treatments include hair restoration, hair loss treatments, medi facials for bride and groom, laser hair reduction, dermal fillers, peels, Hifu and many more.                </p>
              </div>
            </div>
            <div className="col-md-6   d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={EnhanceBridalCareandGroomCare} alt="Enhance BridalCare and Groom Care" />
              </div>
            </div>
          </div>
        </div>
      </div>









      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-2">
              <div className="servcieDetailText">
                <h2>Collagen Induction Facial</h2>
                <p>
                  It is a kind of therapy for collagen induction that is gaining popularity involves microneedling using a dermapen or derma roller. Microneedling increases the absorption of hair and skin products used along with the procedure. It also improves blood supply to skin, improves skin texture and results in collagen induction thereby reducing fine lines, pores, scars and pigmentation. Its powerful independent treatment makes it cost-effective and great skin rejuvenating treatment.                </p>
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={CollagenInductionFacial} alt="Collagen Induction Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>









      <div className="serviceDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="servcieDetailText">
                <h2>Zo obagi Red Carpet Facial</h2>
                <p>
                  ZO red carpet facial peel designed by the dermatologist Dr. Zein Obagi for the red carpet glow, incorporates three essential formulas that prep, peel, and neutralize the skin to look and feel healthier without irritation. ZO facial is a must for skin that feels rough or looks dull, as it uses an advanced combination of exfoliating ingredients to rejuvenate the skin. ZO facial is designed to strengthen skin structure integrity and hydrate to support the visible improvement in skin elasticity and firmness. ZO facial peel contains a unique blend of salicylic, glycolic, and lactic acid to provide a gentle peel, resulting in tighter, smoother, brighter skin immediately after the first session. This facial gives improved outcomes for a range of skin conditions, such as acne, photo-damaged and melasma, fine lines, sebum extraction, pigmentation, and uneven skin tone and texture. In addition, ZO red carpet facial effectively exfoliates the uppermost damaged skin layers, resulting in rejuvenated, youthful, and radiant skin.                </p>
              </div>
            </div>
            <div className="col-md-6   d-flex justify-content-center align-items-center">
              <div className="serviceDetailImg">
                <img src={ZoobagiRedCarpetFacial} alt="Zoobagi RedCarpet Facial" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnhanceMediFacial;
